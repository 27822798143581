@import "../../../app/styles/globalStyles";


.result-page {
  display: flex;
  justify-content: space-between;
  padding: 45px 240px 25px 240px;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;


    &-content {
      background-image: url("../../../shared/ui/img/ResultPage/resultBg.png");
      padding: 35px 86px 75px 86px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;

      &-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 11px;
      }

      &-desc {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        margin-bottom: 52px;
      }

      &-result {
        width: 230px;
        height: 230px;
        position: relative;

        .number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 900;
          font-size: 42px;
          color: #FFFFFF;
          letter-spacing: 0.1em;
        }

        &-border {
          position: absolute;
          box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.5);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 93%;
          height: 93%;
          border-radius: 50%;
          z-index: 1;
        }

        svg {
          width: 100%;
          height: 100%;
          position: relative;


          circle {
            width: 100%;
            height: 100%;
            stroke-width: 3.5px;
          }

        }

        .circle-progress {
          stroke: #FFFFFF;
          z-index: 5;
          position: absolute;
        }

        .circle-background {
          stroke: #2ECC71;
          fill: #2ECC71;
        }


        &_green {
          width: 78.8px;
          height: 78.8px;

          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 900;
            font-size: 14px;
            color: #2ECC71;
            letter-spacing: 0.1em;
          }
          svg {
            width: 100%;
            height: 100%;
            position: relative;


            circle {
              width: 100%;
              height: 100%;
              stroke-width: 3.5px;
            }

          }

          .circle-progress {
            stroke: #2ECC71;
            z-index: 5;
            position: absolute;
          }

          .circle-background {
            stroke: #1C7A44;
            fill: none;
          }
        }

        &_blue {
          width: 78.8px;
          height: 78.8px;

          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 900;
            font-size: 14px;
            color: #21C5EF;
            letter-spacing: 0.1em;
          }
          svg {
            width: 100%;
            height: 100%;
            position: relative;


            circle {
              width: 100%;
              height: 100%;
              stroke-width: 3.5px;
            }

          }

          .circle-progress {
            stroke: #21C5EF;
            z-index: 5;
            position: absolute;
          }

          .circle-background {
            stroke: #13758F;
            fill: none;
          }
        }
        &_false {
          .circle-background {
            stroke: #F15249;
            fill: #F15249;
          }
        }
      }
    }

    &-footer {
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 44px 47px ;

      &-button {
        min-width: 238px;

        border: 2px solid #2ECC71;
        border-radius: 91px;
        padding: 15px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;


        &_disabled {
          opacity: 50%;
          &:hover {
            cursor: not-allowed;
          }
        }

        &_white {
          background: transparent;
          color: #2ECC71;
          margin-bottom: 15px;
          transition: all 0.3s;
        }

        &_green {
          background: #2ECC71;
          color: #FFFFFF;
          margin-bottom: 45px;
          transition: all 0.3s;
        }
      }

      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #2ECC71;
        margin-bottom: 15px;

        &_black {
          color: #4F4F4F;
        }
      }

      &-buttons {
        display: flex;
        justify-content: space-between;
        min-width: 157px;
        align-items: center;

        button {
          background: transparent;
          border: none;
        }

      }
    }
  }

  &__right {
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 1010px;
    padding: 30px;
    min-height: 630px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-header {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 11px;
        padding: 17px 31px;

        img {
          margin-right: 25px;
        }


        &-title {
          font-weight: 900;
          font-size: 45px;
          line-height: 57px;
          background: linear-gradient(180deg, #FF901D 0%, #FED130 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          &_red {
            font-weight: 900;
            font-size: 48px;
            line-height: 61px;
            background: linear-gradient(180deg, #EA4B43 0%, #F15249 48.44%, #AD0E0E 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }

    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 15px;
    }

    &-desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 65px;
    }

    &-conclusion2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-conclusion {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 25px;
      border-bottom: 1px solid #A3A3A3;
      margin-bottom: 40px;

      &-result {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-desc {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          margin-top: 7px;
        }
      }

      &-list {
        list-style: none;

        &-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          color: #000000;
          margin-bottom: 15px;
          display: flex;
          align-items: center;


          &_green {
            color: #2ECC71;
          }

          &_blue {
            color: #20C4F3;
          }

          img {
            margin-right: 15px;
          }
        }

        &-desc {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          max-width: 800px;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;



    &-content {
      display: flex;
      flex-direction: column;
      margin: 0 240px 48px 0;
      background: #FFFFFF;
      border-radius: 14px;
      justify-content: flex-end;
      min-width: 1010px;
      padding: 18px 34px 14px 34px;
    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }

    &-table {
      display: flex;

      &-desc {
        display: flex;
        flex-direction: column;
        width: 139px;
        height: 124px;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #3D3D3D;
        margin-right: 50px;
        &-title {
          margin-bottom: 17px;
        }

        &_active {
          background: #2ECC71;
          border-radius: 17px;
          color: #FFFFFF;
        }

        &-main {
          display: flex;
          flex-direction: column;
          width: 139px;
          height: 124px;
          align-items: flex-start;
          justify-content: center;
          font-weight: 900;
          font-size: 18px;
          line-height: 23px;
          color: #3D3D3D;
          margin-right: 50px;

          &-title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .result-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;


      &-content {
        background-image: url("../../../shared/ui/img/ResultPage/resultBg.png");
        padding: 35px 86px 75px 86px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;

        &-title {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #FFFFFF;
          margin-bottom: 11px;
        }

        &-desc {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;
          margin-bottom: 52px;
        }

        &-result {
          width: 230px;
          height: 230px;
          position: relative;

          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 900;
            font-size: 42px;
            color: #FFFFFF;
            letter-spacing: 0.1em;
          }

          &-border {
            position: absolute;
            box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.5);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 93%;
            height: 93%;
            border-radius: 50%;
            z-index: 1;
          }

          svg {
            width: 100%;
            height: 100%;
            position: relative;


            circle {
              width: 100%;
              height: 100%;
              stroke-width: 3.5px;
            }

          }

          .circle-progress {
            stroke: #FFFFFF;
            z-index: 5;
            position: absolute;
          }

          .circle-background {
            stroke: #2ECC71;
            fill: #2ECC71;
          }


          &_green {
            width: 78.8px;
            height: 78.8px;

            .number {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 900;
              font-size: 14px;
              color: #2ECC71;
              letter-spacing: 0.1em;
            }
            svg {
              width: 100%;
              height: 100%;
              position: relative;


              circle {
                width: 100%;
                height: 100%;
                stroke-width: 3.5px;
              }

            }

            .circle-progress {
              stroke: #2ECC71;
              z-index: 5;
              position: absolute;
            }

            .circle-background {
              stroke: #1C7A44;
              fill: none;
            }
          }

          &_blue {
            width: 78.8px;
            height: 78.8px;

            .number {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 900;
              font-size: 14px;
              color: #21C5EF;
              letter-spacing: 0.1em;
            }
            svg {
              width: 100%;
              height: 100%;
              position: relative;


              circle {
                width: 100%;
                height: 100%;
                stroke-width: 3.5px;
              }

            }

            .circle-progress {
              stroke: #21C5EF;
              z-index: 5;
              position: absolute;
            }

            .circle-background {
              stroke: #13758F;
              fill: none;
            }
          }
          &_false {
            .circle-background {
              stroke: #F15249;
              fill: #F15249;
            }
          }
        }
      }

      &-footer {
        background: #FFFFFF;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        min-width: 400px;

        &-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          color: #2ECC71;
          margin-bottom: 15px;
          text-align: center;
        }

        &-buttons {
          display: flex;
          justify-content: space-between;
          min-width: 157px;
          align-items: center;

          button {
            background: transparent;
            border: none;
          }
        }
      }
    }

    &__right {
      background: #FFFFFF;
      border-radius: 10px;
      max-width: 1010px;
      padding: 15px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        &-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #FFFFFF;
          border-radius: 11px;
          padding: 10px;
          margin-bottom: 10px;

          img {
            margin-right: 0px;
          }


          &-title {
            font-weight: 900;
            font-size: 36px;
            line-height: 40px;
            background: linear-gradient(180deg, #FF901D 0%, #FED130 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-align: center;

            &_red {
              font-weight: 900;
              font-size: 36px;
              line-height: 40px;
              background: linear-gradient(180deg, #EA4B43 0%, #F15249 48.44%, #AD0E0E 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }

      }

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 15px;
      }

      &-desc {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 50px;
      }

      &-conclusion2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &-conclusion {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 25px;
        border-bottom: 1px solid #A3A3A3;
        margin-bottom: 40px;

        &-result {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-desc {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            margin-top: 7px;
          }
        }

        &-list {
          list-style: none;

          &-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
            margin-bottom: 10px;

            img {
              margin-right: 15px;
            }
          }

          &-desc {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            max-width: 200px;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;



      &-content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        background: #FFFFFF;
        border-radius: 14px;
        justify-content: flex-end;
        min-width: 300px;
        padding: 18px 34px 14px 34px;
      }

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
      }

      &-table {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-desc {
          display: flex;
          flex-direction: column;
          width: 139px;
          height: 124px;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #3D3D3D;
          margin-right: 0px;
          &-title {
            margin-bottom: 17px;
          }

          &_active {
            background: #2ECC71;
            border-radius: 17px;
            color: #FFFFFF;
          }

          &-main {
            display: none;
            flex-direction: column;
            width: 139px;
            height: 124px;
            align-items: flex-start;
            justify-content: center;
            font-weight: 900;
            font-size: 18px;
            line-height: 23px;
            color: #3D3D3D;
            margin-right: 50px;

            &-title {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

