@import "../../app/styles/globalStyles";




.SolutionPage {
    width: 100%;

    .breadcrumbs_ {
        width: 100%;
        padding: 20px 240px;
    }

    .SolutionTitle {
        width: 100%;
        height: 340px;
        margin-top: -1px;
        position: relative;

        @include flex(flex, row, nowrap, center, center, unset);

        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            color: #FFFFFF;
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
        }
    }

    .advantages {
        width: 100%;
        padding: 20px 240px;
        margin-top: 100px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        p {
            width: 1091px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            margin-bottom: 50px;
        }
        .itemsWrapper {
            width: 100%;
            gap: 20px;

            @include flex(flex, row, nowrap, center, unset, unset);

            .item {
                width: 270px;
                gap: 40px;

                @include flex(flex, column, nowrap, flex-start, center, unset);

                .icon {
                    width: 90px;
                    height: 90px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                    color: #393939;

                    span {
                        color: #2ECC71;
                    }
                }
            }

            .line {
                min-height: 100%;
                width: 1px;
                background-color: #9F9F9F;
            }
        }
    }

    .steps {
        width: 100%;
        padding: 20px 240px;
        margin-top: 135px;
        margin-bottom: 170px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        .row {
            width: 100%;

            @include flex(flex, row, nowrap, space-between, center, unset);

            .text {
                width: 727px;
                gap: 18px;

                @include flex(flex, column, nowrap, flex-start, center, unset);

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: uppercase;
                    color: #656565;
                }
                h3 {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 45px;
                    color: #2ECC71;
                }
                p {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #000000;
                }
            }
            .img {
                width: 400px;
                height: 400px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
        }
    }
}






@media screen and (max-width: 500px) {
    .SolutionPage {
        width: 100%;

        .breadcrumbs_ {
            width: 100%;
            padding: 20px;
        }
        .SolutionTitle {
            width: 100%;
            padding: 20px;
            height: 210px;

            h1 {
                font-size: 36px*0.75;
                line-height: 45px*0.75;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .advantages {
            width: 100%;
            padding: 20px;
            margin-top: 50px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            p {
                width: 100%;
                font-size: 20px;
                line-height: 24px;
            }
            .itemsWrapper {
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;

                .item {
                    width: 270px;
                    gap: 40px;

                    span {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }

                .line {
                    display: none;
                }
            }
        }

        .steps {
            width: 100%;
            padding: 20px;
            margin-top: 80px;
            margin-bottom: 80px;

            .row {
                width: 100%;

                @include flex(flex, row, nowrap, space-between, center, unset);

                .text {
                    width: 60%;
                    gap: 18px;

                    @include flex(flex, column, nowrap, flex-start, center, unset);

                    span {
                        font-size: 24px*0.7;
                        line-height: 29px*0.7;
                    }
                    h3 {
                        font-size: 36px*0.6;
                        line-height: 45px*0.6;
                    }
                    p {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                .img {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }
}