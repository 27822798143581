@import "../../../../app/styles/globalStyles";

.answerButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #2ECC71;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border: 2px solid #2ECC71;
  border-radius: 57px;

  img {
    margin-right: 25px;
  }
}

@media screen and (max-width: 500px) {
  .answerButton {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #2ECC71;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    border: 2px solid #2ECC71;
    border-radius: 57px;
    margin-bottom: 10px;

    img {
      margin-right: 25px;
    }
  }
}
