

.studying-note {
  width: 100%;
  padding: 33px 240px 60px 0;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #000000;
    margin-bottom: 31px;
  }
}


@media screen and (max-width: 500px) {
  .studying-note {
    width: 100%;
    padding: 20px 20px 20px 10px;

    &__title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #000000;
      margin-bottom: 31px;
    }

    &__table {
      overflow-y: auto;
      margin-bottom: 50px;
    }
  }
}