@import "../../app/styles/globalStyles";

.quick-check {

  &__breadcrumbs {
    padding: 50px 240px 0px 240px;
  }

  &__content {
    padding: 100px 240px;
  }

  &__button {
    min-width: 275px;
    border: 2px solid #FFFFFF;
    border-radius: 60px;
    background: transparent;
    padding: 14px 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #444444;
    text-align: center;
    margin-bottom: 50px;


    span {
      font-weight: 900;
      color: #2ECC71;
      margin-top: 15px;
      display: block;
    }

    &_green {
      font-weight: 700;
      color: #2ECC71;
    }

    &_white {
      text-align: left;
      font-weight: 700;
      color: #FFFFFF;
    }
  }


  &__bg1 {
    background-image: url("../../shared/ui/img/QuickCheckPage/bg1.png");
    padding: 114px 115px 103px 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;

    &__number {
      display: flex;
      align-items: center;
      margin-right: 209px;

      &__number {
        font-weight: 900;
        font-size: 128px;
        line-height: 162px;
        color: #FFFFFF;
        margin-right: 17px;
      }

      &__desc {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;

        &__min {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 15px;
        }
      }
    }

    &__desc {

      &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 63px;
      }

      &__list {
        list-style: none;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 26px;

          &:last-child {
            margin-bottom: 70px;
          }

          span {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #FFFFFF;
            margin-left: 23px;
          }
        }
      }
    }
  }

  &__desc-row {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    max-width: 383px;

    &__img {
      margin-bottom: 22px;
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 10px;

      &_green {
        color: #2ECC71;
      }
    }

    &__desc {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #555555;
    }
  }

  &__bg2 {
    background-image: url("../../shared/ui/img/QuickCheckPage/bg2.png");
    padding: 119px 308px 118px 240px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {

      &__desc {
        max-width: 814px;
        font-weight: 400;
        font-size: 20px;
        line-height: 38px;
        color: #000000;
        margin-bottom: 50px;
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .quick-check {

    &__breadcrumbs {
      padding: 20px;
    }

    &__content {
      padding: 20px;
    }

    &__button {
      min-width: 275px;
      border: 2px solid #FFFFFF;
      border-radius: 60px;
      background: transparent;
      padding: 14px 14px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: #444444;
      text-align: center;
      margin-bottom: 30px;


      span {
        font-weight: 900;
        color: #2ECC71;
        margin-top: 15px;
        display: block;
      }

      &_green {
        font-weight: 700;
        color: #2ECC71;
      }

      &_white {
        text-align: center;
        font-weight: 700;
        color: #FFFFFF;
      }
    }


    &__bg1 {
      background-image: url("../../shared/ui/img/QuickCheckPage/bg1.png");
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      background-size: cover;

      &__number {
        display: flex;
        align-items: center;
        margin: 0 auto;

        &__number {
          font-weight: 900;
          font-size: 100px;
          line-height: 162px;
          color: #FFFFFF;
          margin-right: 17px;
        }

        &__desc {
          display: flex;
          flex-direction: column;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #FFFFFF;

          &__min {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 10px;
          }
        }
      }

      &__desc {
        display: flex;
        flex-direction: column;

        &__title {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #FFFFFF;
          margin-bottom: 40px;
        }

        &__list {
          list-style: none;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 40px;
            }

            span {
              font-weight: 700;
              font-size: 15px;
              line-height: 25px;
              color: #FFFFFF;
              margin-left: 15px;
            }
          }
        }
      }
    }

    &__desc-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__card {
      max-width: 383px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__img {
        margin-bottom: 15px;
      }

      &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        margin-bottom: 10px;
      }

      &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #555555;
        margin-bottom: 30px;
        text-align: center;
      }
    }

    &__bg2 {
      background-image: url("../../shared/ui/img/QuickCheckPage/bg2.png");
      background-size: cover;
      padding: 20px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &__img {
        margin-top: 30px;

        img {
          width: 200px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__desc {
          max-width: 814px;
          font-weight: 400;
          font-size: 15px;
          line-height: 38px;
          color: #000000;
          margin-bottom: 30px;
        }
      }
    }
  }
}