@import "../../../app/styles/globalStyles";



.StudyingLesson {
    width: 1200px;
    margin-top: 30px;
    margin-bottom: 50px;

    h1 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
        margin-bottom: 30px;
    }
    .video {
        width: 100%;
        height: 633px;
        border-radius: 13px;
        overflow: hidden;
        margin-bottom: 30px;

        video {
            width: 100%;
            height: 100%;
        }
        iframe {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: none;
        }
    }
    .underVideoBtns {
        width: 100%;
        gap: 50px;
        margin-bottom: 30px;

        @include flex(flex, row, nowrap, flex-start, center, unset);

        button {
            gap: 10px;
            background-color: transparent;
            border: none;
            outline: none;

            @include flex(flex, row, nowrap, center, center, unset);

            .icon {
                width: 23px;
                height: 23px;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            span {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #424242;
            }
        }
    }
    .Block {
        width: 100%;
        margin: 30px 0;

        h4 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #000000;
            margin-bottom: 40px;
        }
        .textBlockContent {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 179.8%;
            color: #000000;
        }
        .materialsBlockContent {
            width: 100%;

            table {
                width: 100%;
                border-collapse: collapse;

                th {
                    height: 61px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #000000;
                    text-align: left;
                    
                    &:first-child {
                        padding-left: 48px;
                    }
                }
                th,
                td {
                    &:nth-child(3) {
                        width: 200px;
                    }
                    &:last-child {
                        width: 100px;
                    }
                }
                tbody {
                    tr {
                        td {
                            background-color: #1B8C4B;
                            padding: 14px 0;
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            color: #FFFFFF;

                            &:first-child {
                                padding-left: 48px;
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            }
                            &:last-child {
                                padding-right: 48px;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }

                            button {
                                padding: 10px 20px;
                                background: #FFFFFF;
                                border: 2px solid #FFFFFF;
                                border-radius: 57px;
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 17px;
                                color: #2ECC71;
                            }
                        }
                        &:nth-child(2n + 1) {
                            td {
                                background-color: #2ECC71;
                            }
                        }
                    }
                }
            }

            .noStudents {
                width: fit-content;
                margin: 0 auto;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000;
            }
        }
        .practicWorkContent {
            width: 100%;
        }
    }



    
}














@media screen and (max-width: 500px) {
    .StudyingLesson {
        width: 100%;
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 50px;
    
        h1 {
            font-size: 36px*0.7;
            line-height: 45px*0.7;
            margin-bottom: 30px;
        }
        .video {
            width: 100%;
            height: 250px;

            video {
                border-radius: 13px;
            }
        }
        .underVideoBtns {
            width: 100%;
            gap: 30px;
            margin-bottom: 30px;
    
            @include flex(flex, row, wrap, flex-start, center, unset);
    
            button {
                .icon {
                    width: 23px*0.9;
                    height: 23px*0.9;
                }
                span {
                    font-size: 20px*0.9;
                    line-height: 24px*0.9;
                }
            }
        }
        .Block {
            width: 100%;
            margin: 30px 0;
    
            h4 {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 40px;
            }
            .textBlockContent {
                p {
                    font-size: 20px;
                    line-height: 179.8%;
                }
            }
            .materialsBlockContent {
                overflow-x: auto;
                
                table {
                    th {
                        height: 61px*0.8;
                        font-size: 20px*0.8;
                        line-height: 25px*0.8;
                        
                        &:first-child {
                            padding-left: 18px;
                        }
                    }
                    th,
                    td {
                        &:nth-child(3) {
                            width: 200px;
                        }
                        &:last-child {
                            width: 100px;
                        }
                    }
                    tbody {
                        tr {
                            td {
                                padding: 7px;
                                font-size: 18px*0.8;
                                line-height: 22px*0.8;
    
                                &:first-child {
                                    padding-left: 18px;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }
                                &:last-child {
                                    padding-right: 18px;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
    
                                button {
                                    padding: 5px 15px;
                                    border-radius: 57px*0.8;
                                    font-size: 14px*0.8;
                                    line-height: 17px*0.8;
                                }
                            }
                        }
                    }
                }
    
                .noStudents {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            .practicWorkContent {
                width: 100%;
            }
        }
    }
}