@import "../../../../app/styles/globalStyles";



.TestAudio {
    width: fit-content;
    gap: 15px;
    margin-bottom: 20px;

    @include flex(flex, row, nowrap, space-between, center, unset);

    .play {
        width: 50px;
        height: 50px;
    }
    .audioText {
        .title {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 5px;

            span {
                font-weight: 900;
            }
        }
        .length {
            font-family: 'Gilroy';
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
    }
}