@import "../../../app/styles/globalStyles";

.rules {
  width: 100%;
  background-color: #2ECC71;
  height: 100%;
  padding: 65px 240px 85px 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;


  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  &__desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

  &__list {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        margin-right: 23px;
      }

      span {
        display: flex;
        align-items: center;
      }
    }

    &-text_bg {
      padding: 5px 20px;
      background: linear-gradient(180deg, #2ECC71 0%, #20C4F3 100%);
      border-radius: 3px;
      color: #FFFFFF;
      margin: 0 12px;
    }
  }

  &__container {
    min-width: 1440px;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 80px 47px;
    margin-bottom: 48px;

    &-list {
      margin-bottom: 87px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #2ECC71;
        display: flex;
        align-items: center;
        margin-bottom: 28px;

        img {
          margin-right: 15px;
        }
      }
    }
  }

  &__button {
    min-width: 219px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 60px;
    padding: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #2ECC71;
  }
}

@media screen and (max-width: 500px) {

  .rules {
    width: 100%;
    background-color: #2ECC71;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;


    &__title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    &__desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      margin-bottom: 30px;
      text-align: center;
    }

    &__list {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 25px;
        color: #000000;
        margin-bottom: 26px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          margin-right: 10px;
        }

        span {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      &-text_bg {
        padding: 5px 20px;
        background: linear-gradient(180deg, #2ECC71 0%, #20C4F3 100%);
        border-radius: 3px;
        color: #FFFFFF;
        margin: 0 12px;
      }
    }

    &__container {
      min-width: 100%;
      background-color: #FFFFFF;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-list {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #2ECC71;
          display: flex;
          align-items: center;
          margin-bottom: 28px;

          img {
            margin-right: 15px;
          }
        }
      }
    }

    &__button {
      min-width: 219px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 60px;
      padding: 14px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #2ECC71;
    }
  }
}