

.diplom {
  display: flex;
  flex-direction: column;
  max-width: 408px;
  margin-bottom: 50px;

  img {
    width: 408px;
    height: 576px;
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }

    button {
      min-width: 238px;
      background: #2ECC71;
      border: 2px solid #2ECC71;
      border-radius: 91px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      padding: 15px;
    }
  }
}

@media screen and (max-width: 500px){
  .diplom {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 408px;
    margin-bottom: 30px;

    img {
      width: 200px;
      height: 292px;
      margin-bottom: 15px;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 10px;
      }

      button {
        min-width: 200px;
        background: #2ECC71;
        border: 2px solid #2ECC71;
        border-radius: 91px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        padding: 15px;
      }
    }
  }
}