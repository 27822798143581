@import "../../../../app/styles/globalStyles";

.materials-table {
  width: 100%;


  table {
    width: 100%;
    border-collapse: collapse;


    th {
      text-align: left;
      padding-bottom: 15px;

      &:first-child {
        padding-left: 24px;
      }

      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #2ECC71;
      }

      .tableTitleDrop {
        margin-left: 10px*$vw;
        @include flex(flex, row, nowrap, center, center, unset);
        gap: 5px*$vw;
        cursor: pointer;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px*$vw;
        line-height: 20px*$vw;
        color: #FFFFFF;
      }
    }

    .noMaterials {
      font-weight: 600;
      font-size: 24px;
      line-height: 22px;
      color: black;
    }
  }
}

@media screen and (max-width: 500px) {
  .materials-table {
    width: 100%;


    table {
      width: 100%;
      border-collapse: collapse;


      th {
        text-align: left;
        padding: 5px;

        &:first-child {
          padding-left: 5px;
        }

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #2ECC71;
        }

        .tableTitleDrop {
          margin-left: 10px*$vw;
          @include flex(flex, row, nowrap, center, center, unset);
          gap: 5px*$vw;
          cursor: pointer;

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px*$vw;
          line-height: 20px*$vw;
          color: #FFFFFF;
        }
      }

      .noMaterials {
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        color: black;
      }
    }
  }
}