@import "../../app/styles/globalStyles";



.ForumPage {
    width: 100%;
    margin-bottom: 100px;

    .breadcrumbs_ {
        width: 100%;
        padding: 20px 240px;
    }

    .ForumTitle {
        width: 100%;
        height: 151px;
        margin-top: -1px;
        position: relative;
        z-index: 3;
        margin-bottom: 40px;

        @include flex(flex, row, nowrap, space-between, center, unset);

        .left {
            h1 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 45px;
                color: #FFFFFF;
                padding-left: 264px;
            }
            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #F7F7F7;
                padding-left: 264px;
            }
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: -1;
        }

        .right {
            padding-right: 240px;

            .textInBtn {
                @include flex(flex, row, nowrap, space-between, center, unset);

                gap: 25px;

                .icon {
                    width: 19.4px;
                    height: 17px;

                    @include flex(flex, row, nowrap, center, center, unset);

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .forumWrapper {
        width: 100%;
        padding: 20px 240px;
        gap: 80px;

        @include flex(flex, row, nowrap, space-between, flex-start, unset);
    }
    .forumListWrapper {
        width: 100%;

        .forumList {
            width: 100%;

            .oneTheme {
                width: 100%;
                padding: 25px 0;
                border-bottom: 1px solid #ADADAD;

                @include flex(flex, row, nowrap, space-between, center, unset);

                .left {
                    gap: 25px;

                    @include flex(flex, row, nowrap, flex-start, flex-start, unset);

                    .themeContent {
                        h3 {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            color: #000000;
                            margin-bottom: 15px;
                        }
                        .items {
                            width: 100%;
                            gap: 20px;

                            @include flex(flex, row, nowrap, flex-start, center, unset);
                            
                            .item {
                                @include flex(flex, row, nowrap, flex-start, center, unset);

                                gap: 10px;

                                span {
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #000000;
                                }
                            }

                            .icon {
                                width: 25px;
                                height: 25px;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
                .right {
                    @include flex(flex, row, nowrap, flex-start, center, unset);

                    gap: 60px;

                    .themesAns {
                        @include flex(flex, row, nowrap, flex-start, unset, unset);

                        gap: 16px;

                        .col {
                            @include flex(flex, column, nowrap, flex-start, center, unset);

                            span {
                                &:nth-child(1) {
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #2ECC71;
                                }
                                &:nth-child(2) {
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #7D7D7D;
                                }
                            }
                        }
                        .line {
                            width: 1px;
                            min-height: 100%;
                            background-color: #7D7D7D;
                        }
                    }
                }
            }
            .noQuestions {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                text-align: center;
                margin-top: 50px;
            }
        }

        .pagination {
            width: fit-content;
            margin: 0 auto;
            margin-top: 50px;
        }
    }
}







@media screen and (max-width: 500px) {
    .ForumPage {
        .breadcrumbs_ {
            padding: 20px;
        }

        .ForumTitle {
            h1 {
                font-size: 36px*0.8;
                line-height: 45px*0.8;
                padding-left: 20px;
            }
            p {
                padding-left: 20px;
            }
        }

        .forumWrapper {
            width: 100%;
            padding: 20px;
            gap: 0;
            display: block;
        }
        .forumListWrapper {
            position: relative;

            .sidebarBurger {
                width: 30px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 0;

                @include flex(flex, column, nowrap, space-between, center, unset);

                div {
                    width: 100%;
                    height: 2px;
                    background-color: #000;
                    border-radius: 5px;
                }
            }
            .forumList {
                .oneTheme {
                    width: 100%;
                    padding: 15px 0;

                    .left {
                        max-width: 80%;
                        gap: 10px;

                        .themeContent {
                            h3 {
                                font-size: 18px*0.85;
                                line-height: 22px*0.85;
                                margin-bottom: 10px;
                            }
                            .items {
                                width: 100%;
                                gap: 10px;
                                flex-wrap: wrap;
                                
                                .item {
                                    @include flex(flex, row, nowrap, flex-start, center, unset);

                                    gap: 5px;

                                    span {
                                        font-size: 14px*0.9;
                                        line-height: 17px*0.9;
                                    }
                                }

                                .icon {
                                    width: 25px*0.7;
                                    height: 25px*0.7;
                                }
                            }
                        }
                    }
                    .right {
                        max-width: 30%;

                        @include flex(flex, row, nowrap, flex-end, center, unset);

                        gap: 10px;

                        .themesAns {
                            max-width: 50%;
                            @include flex(flex, row, wrap, center, unset, unset);

                            gap: 5px;

                            .col {
                                @include flex(flex, column, nowrap, flex-start, center, unset);

                                span {
                                    &:nth-child(1) {
                                        font-size: 14px*0.8;
                                        line-height: 17px*0.8;
                                    }
                                    &:nth-child(2) {
                                        font-size: 14px*0.8;
                                        line-height: 17px*0.8;
                                    }
                                }
                            }
                            .line {
                                width: 100%;
                                min-height: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}