@import "../../../../app/styles/globalStyles";



.FutureLesson {
    width: 100%;
    padding: 38px 25px 14px 23px;
    gap: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    border-left: 8px solid #20C4F3;

    @include flex(flex, column, nowrap, flex-start, flex-start, unset);

    .top {
        width: 100%;

        @include flex(flex, row, nowrap, space-between, flex-start, unset);

        .item {
            @include flex(flex, column, nowrap, flex-start, center, unset);

            h5 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: #2ECC71;
                margin-bottom: 5px;
            }
            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #4D4D4D;
            }
            &.alignRight {
                align-items: flex-start;
            }
            .icon {
                width: 27px;
                height: 27px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .bottom {
        width: 100%;
        @include flex(flex, row, nowrap, flex-start, flex-end, unset);
        gap: 15px;

        h5 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #2ECC71;
        }
        p {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #4D4D4D;
            margin-bottom: 3px;
        }
        .link {
            max-width: 100%;
            overflow: hidden;
        }
    }
}






@media screen and (max-width: 500px) {
    .FutureLesson {
        padding: 38px*0.8 25px*0.8 14px*0.8 23px*0.8;
        gap: 20px;
        border-radius: 9px*0.8;
        border-left: 8px*0.8 solid #20C4F3;
        position: relative;
    
        .top {
            gap: 20px;

            @include flex(flex, row, wrap, flex-start, flex-start, unset);

            .item {
                h5 {
                    font-size: 24px*0.7;
                    line-height: 30px*0.7;
                    margin-bottom: 5px*0.7;
                }
                p {
                    font-size: 16px*0.8;
                    line-height: 19px*0.8;
                }
                &.alignRight {
                    align-items: flex-start;
                }
                .icon {
                    width: 27px*0.8;
                    height: 27px*0.8;

                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
        .bottom {
            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            gap: 15px*0.8;
    
            h5 {
                font-size: 24px*0.7;
                line-height: 30px*0.7;
            }
            p {
                font-size: 16px*0.8;
                line-height: 19px*0.8;
                margin-bottom: 3px*0.8;
            }
        }
    }
}