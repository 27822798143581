@import "../../../../app/styles/globalStyles";



.SignBtnBurger {
    display: none;
}






@media screen and (max-width: 500px) {
    .SignBtnBurger {
        width: 30px;
        height: 20px;
        background-color: transparent;
        border: none;
        outline: none;
        display: block;

        .icon {
            width: 25px;
            height: 25px;

            &.open {
                display: none;
            }

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .close {
            position: relative;
            display: none;

            div {
                width: 100%;
                height: 2px;
                background-color: #282828;
                border-radius: 2px;

                &:first-child {
                    position: absolute;
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:last-child {
                    position: absolute;
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }

            &.open {
                display: block;
            }
        }
        
    }
}