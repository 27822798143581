@import "../../../app/styles/globalStyles";



.btn1 {
  padding: 16px;
  border-radius: 60px;
  border: none;
  outline: none;
  width: auto;
  background: #FFFFFF;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.btn2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  background: transparent;
  border: 2px solid #2ECC71;
  border-radius: 85px;
  padding: 10px;
  transition: all 0.3s;
}




@media screen and (max-width: 500px) {
  .btn1 {
    padding: 13px;
    border-radius: 60px;
    border: none;
    outline: none;
    width: 300px;
    background: #FFFFFF;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;

  }
}