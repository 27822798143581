
.shareBlock {
  display: flex;
  align-items: center;

  .shareButton {
    border: none;
    display: flex;
    align-items: center;
    margin-right: 22px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      margin-left: 10px;
    }
  }

  .viewsBlock {
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      margin-left: 10px;
    }
  }
}