

.courses {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media screen and (max-width: 500px){
  .courses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
      border-radius: 17px;
      padding: 28px 35px;
      max-height: 332px;
      margin-bottom: 30px;

      &-title {
        font-weight: 800;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
        margin-bottom: 25px;
      }

      &-buttons {
        display: flex;
        flex-direction: column;

        &-button {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          background: transparent;
          border: 2px solid #2ECC71;
          border-radius: 85px;
          min-width: 200px;
          padding: 10px;
          margin-bottom: 20px;
          transition: all 0.3s;

          &:hover {
            background: #2ECC71;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}