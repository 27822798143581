@import "../../../../app/styles/globalStyles";


.TestContent {
    .answers {
        width: 100%;
        gap: 19px;
    
        @include flex(flex, row, wrap, space-between, center, unset);
    }
}