@import "../../../../app/styles/globalStyles";


.students-table {
  width: 100%;


  table {
    width: 100%;
    border-collapse: collapse;


    th {
      text-align: left;
      padding-bottom: 29px;

      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
      }

      .tableTitleDrop {
        margin-left: 10px*$vw;
        @include flex(flex, row, nowrap, center, center, unset);
        gap: 5px*$vw;
        cursor: pointer;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px*$vw;
        line-height: 20px*$vw;
        color: #FFFFFF;
      }
    }

    .noStudents {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #00AD49;
    }
  }

  .pagination {
    width: fit-content;
    margin: 0 auto;
    margin-top: 80px;
  }
}

@media screen and (max-width: 500px){
  .students-table {
    width: 100%;
    overflow-x: auto;


    table {
      width: 100%;
      border-collapse: collapse;


      th {
        text-align: left;
        padding-bottom: 15px;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }

        .tableTitleDrop {
          margin-left: 10px*$vw;
          @include flex(flex, row, nowrap, center, center, unset);
          gap: 5px*$vw;
          cursor: pointer;

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px*$vw;
          line-height: 20px*$vw;
          color: #FFFFFF;
        }
      }

      .noStudents {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #00AD49;
      }
    }

    .pagination {
      width: fit-content;
      margin: 0 auto;
      margin-top: 40px;
    }
  }
}