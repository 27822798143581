@import "../../app/styles/globalStyles";



.RatingScalePage {
    width: 100%;

    .breadcrumbs_ {
        width: 100%;
        padding: 20px 240px;
    }

    .levelsTitle {
        width: 100%;
        height: 340px;
        margin-top: -1px;
        position: relative;

        @include flex(flex, row, nowrap, center, center, unset);

        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            color: #FFFFFF;
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
        }
    }

    .aboutTest {
        width: 100%;
        padding: 20px 240px;
        margin-top: 100px;
        margin-bottom: 100px;

        @include flex(flex, row, nowrap, space-between, center, unset);

        .left {
            width: 646px;

            span {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #989898;
            }
            h2 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 45px;
                color: #2ECC71;
                margin-bottom: 27px;
            }
            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
            }
        }
        .right {
            .img {
                width: 718px;
                height: 337px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .levels {
        width: 100%;
        padding: 180px 240px;
        background-color: #2ECC71;
        position: relative;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        .row {
            width: 646px;
            padding: 0 20px;
            position: relative;

            h3 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 30px;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #F2F2F2;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 10px;
                height: 100%;
                background: #21C5EF;
                border-radius: 32px;
            }
            &:nth-child(2n) {
                align-self: flex-end;
                text-align: end;

                &::before {
                    left: unset;
                    right: 0;
                }
            }
        }

        .BG {
            width: 100vw;
            height: 240px;
            position: absolute;
            left: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &.top {
                top: 10px;
            }
            &.bottom {
                bottom: 10px;
            }
        }
    }

    .gradesScale {
        width: 100%;
        padding: 20px 240px;
        margin-bottom: 50px;

        h3 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            color: #323232;
            margin-bottom: 50px;
        }

        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            .row1,
            .row2 {
                width: 100%;
                gap: 2px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                .item {
                    width: 211px;
                    height: 100px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FFFFFF;
                    transition: 0.2s;

                    @include flex(flex, row, nowrap, center, center, unset);

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
            .row1 .item {
                background-color: #2ECC71;
            }
            .row2 .item {
                background-color: #21C5EF;
            }
        }
    }
}










@media screen and (max-width: 500px) {
    .RatingScalePage {
        width: 100%;

        .breadcrumbs_ {
            padding: 20px;
        }

        .levelsTitle {
            width: 100%;
            padding: 20px;
            height: 210px;

            h1 {
                font-size: 36px*0.75;
                line-height: 45px*0.75;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .aboutTest {
            width: 100%;
            padding: 20px;
            margin-top: 50px;
            margin-bottom: 50px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            .left {
                width: 100%;

                span {
                    font-size: 20px;
                    line-height: 25px;
                }
                h2 {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 45px;
                    color: #2ECC71;
                    margin-bottom: 27px;
                }
                p {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                }
            }
            .right {
                .img {
                    width: 100%;
                    height: 280px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .levels {
            width: 100%;
            padding: 100px 20px;
            background-color: #2ECC71;
            position: relative;
            gap: 20px;

            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            .row {
                width: 100%;
                padding: 0 10px;
                position: relative;

                h3 {
                    font-size: 24px*0.75;
                    line-height: 30px*0.75;
                    margin-bottom: 20px*0.75;
                }
                p {
                    font-size: 20px*0.75;
                    line-height: 24px*0.75;
                }

                &::before {
                    left: 0;
                    width: 5px;
                    height: 100%;
                    border-radius: 32px;
                }
                &:nth-child(2n) {
                    align-self: flex-end;
                    text-align: end;

                    &::before {
                        left: unset;
                        right: 0;
                    }
                }
            }

            .BG {
                height: 60px;

                &.top {
                    top: 5px;
                }
                &.bottom {
                    bottom: 5px;
                }
            }
        }

        .gradesScale {
            width: 100%;
            padding: 20px 20px;
            margin-bottom: 50px;

            h3 {
                font-size: 36px*0.7;
                line-height: 45px*0.7;
                margin-bottom: 30px;
            }

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1px;

                .row1,
                .row2 {
                    width: 100%;
                    gap: 1px;

                    @include flex(flex, row, nowrap, flex-start, center, unset);

                    .item {
                        width: 100%;
                        height: 50px;
                        padding: 5px;
                        font-size: 20px*0.7;
                        line-height: 24px*0.7;

                        @include flex(flex, row, nowrap, center, center, unset);

                        span {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}