@import "../../app/styles/globalStyles";

.singleQuestion_wrapper {

  .singleQuestion_breadcrumbs {

    padding: 50px 240px 25px 240px;
  }

  .singleQuestion_bg {

    background-image: url("../../shared/ui/img/singleQuestionBg.png");
    padding: 41px 240px 32px 264px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_content {

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      &_share {
        display: flex;
        align-items: center;

        button {
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: #FFFFFF;
          margin-right: 15px;
          align-items: center;
          display: flex;
          background: transparent;
          border: none;
        }

        span {
          margin-right: 15px;
        }
      }
    }

    &_button {
      background: transparent;
      border: 2px solid #FFFFFF;
      border-radius: 57px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 15px 25px;

      img {
        margin-right: 25px;
      }
    }
  }

  .singleQuestion_content {
    padding: 50px 240px 50px 240px;


    &_question {
      padding: 25px;
      background: #F2F2F2;
      box-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      &_header {

        border-bottom: 0.5px solid #3E3D3D;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 25px;

        &_content {

          &_title {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #323232;

          }

          &_desc {
            display: flex;
            padding: 20px 0 15px 0;


            &_row {
              display: flex;
              align-items: center;
              margin-right: 30px;

              span {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                margin-left: 10px;
              }
            }
          }
        }
      }

      &_content {
        margin-bottom: 40px;
        transition: 0.3s;

        &_hide {
          max-height: 50px;
          overflow: hidden;
        }

        &_desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 25px;
        }
      }

      &_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.field {
  width: 100%;

  @include flex(flex, column, nowrap, flex-start, flex-start, unset);


  .title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 27px;
  }
  input,
  select {
    width: 100%;
    height: 41px;
    background: #FAFAFA;
    border-radius: 62px;
    border: none;
    outline: none;
    padding-left: 23px;
  }
  .fieldImgContent {
    width: 100%;
    gap: 50px;

    @include flex(flex, row, wrap, flex-start, center, unset);
  }
  .addImg {
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 20px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 500px) {
  .singleQuestion_wrapper {

    .singleQuestion_breadcrumbs {

      padding: 20px;
    }

    .singleQuestion_bg {

      background-image: url("../../shared/ui/img/singleQuestionBg.png");
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-size: cover;
      align-items: center;
      justify-content: space-between;

      &_content {

        &_title {
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #FFFFFF;
          margin-bottom: 10px;
        }

        &_share {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          button {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: #FFFFFF;
            margin-right: 15px;
            align-items: center;
            display: flex;
            background: transparent;
            border: none;
          }

          span {
            margin-right: 15px;
          }
        }
      }

      &_button {
        background: transparent;
        border: 2px solid #FFFFFF;
        border-radius: 57px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 15px 25px;

        img {
          margin-right: 25px;
        }
      }
    }

    .singleQuestion_content {
      padding: 10px;


      &_question {
        padding: 15px;
        background: #F2F2F2;
        box-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        &_header {

          border-bottom: 0.5px solid #3E3D3D;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          &_content {

            &_title {
              font-weight: 600;
              font-size: 20px;
              line-height: 29px;
              color: #323232;
              text-align: center;
            }

            &_desc {
              display: flex;
              padding: 10px 0 10px 0;


              &_row {
                display: flex;
                align-items: center;
                margin-right: 15px;

                span {
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  color: #000000;
                  margin-left: 5px;
                }
              }
            }
          }
        }

        &_content {
          margin-bottom: 40px;
          transition: 0.3s;

          &_hide {
            max-height: 50px;
            overflow: hidden;
          }

          &_desc {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 25px;
          }

          img {
            max-width: 300px;
          }
        }

        &_footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}