@import "../../../app/styles/globalStyles";


.breadcrumbs {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    border-bottom: 0.75px solid #404040;

    @include flex(flex, row, nowrap, flex-start, center, unset);

    .icon {
        width: 30px;
        height: 25px;
        margin-right: 15px;

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .crumb {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #3E3D3D;
    }
    .crumb_curr {
        font-weight: 700;
        background: linear-gradient(180deg, #2ECC71 0%, #21C5EF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}






@media screen and (max-width: 500px) {

    .breadcrumbs {
        width: 100%;
        padding: 10px 0 10px 0;
        border-bottom: 0.5px solid #9D9B9B;

        .crumb {
            font-size: 14px;
            line-height: 17px;
        }
    }
}