@import "../../app/styles/globalStyles";


.allContent {
  width: 100%;
  margin-bottom: -50px;

  @include flex(flex, row, nowrap, flex-start, flex-start, unset);

    .contentPlace {
        width: 100%;
        margin-top: 64px;
    }
}

