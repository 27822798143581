@import "../../../../../app/styles/globalStyles";

.material-row {
  width: 100%;
  background-color: #2ECC71;

  td {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 7px 0;

    /*  */

    &:first-child {
      padding-left: 24px;
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      padding-right: 38px;
      border-radius: 0 5px 5px 0;
    }
  }

  &:nth-child(4n + 1) {
    background-color: #09A74C;
  }

  &__button {
    text-align: end;

    button {
      width: 100px;
      padding: 10px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 57px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #2ECC71;

    }
  }
}

@media screen and (max-width: 500px){
  .material-row {
    width: 100%;
    background-color: #2ECC71;

    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      padding: 7px 5px;

      /*  */

      &:first-child {
        padding-left: 5px;
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        padding-right: 5px;
        border-radius: 0 5px 5px 0;
      }
    }

    &:nth-child(4n + 1) {
      background-color: #09A74C;
    }

    &__button {
      text-align: start;

      button {
        width: 100px;
        padding: 10px;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 57px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #2ECC71;

      }
    }
  }
}