@import "../../../app/styles/globalStyles";



.CheckAudio {
    width: 100%;
    padding: 180px 0;
    background-color: #2ECC71;
    position: relative;

    @include flex(flex, column, nowrap, center, center, unset);

    .CheckAudioContent {
        width: 1440px;
        height: 576px;
        background: #FFFFFF;
        border-radius: 5px;
        position: relative;
        z-index: 2;

        @include flex(flex, column, nowrap, center, center, unset);

        .close {
            display: none;
        }

        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #464646;
            margin-bottom: 25px;
        }
        p {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 65px;
        }
        .btns {
            margin-top: 65px;
            gap: 30px;

            @include flex(flex, row, nowrap, space-between, center, unset);

            button {
                padding: 14px 50px;
                border: 2px solid #2ECC71;
                border-radius: 60px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #2ECC71;
                background-color: transparent;

                &.yes {
                    background: #2ECC71;
                    color: #fff;
                }
            }
        }
        &.expand {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);

            .close {
                display: block;
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
                font-size: 30px;
            }
        }
    }
    .noSoundWrapper {
        width: 1440px;
        display: none;

        &.show {
            display: block;
        }
    }
    .noSound {
        width: 100%;
        padding: 50px;
        background-color: #20C4F3;
        
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        transform: translate(0, -100%);

        &.show {
            visibility: visible;
            opacity: 1;
            transform: translate(0, 0);
        }

        h3 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #FFFFFF;
            margin-bottom: 44px;
        }
        .recomendationsList {
            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            gap: 26px;

            .row {
                width: 100%;
                gap: 23px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #EDEDED;
                }
            }
        }

        &.showNoSound {

        }
    }
    /* .expandBtn {
        position: absolute;
        bottom: 80px;
        right: 75px;
        background-color: transparent;
        border: none;
        outline: none;
        width: fit-content;
        height: fit-content;
        transition: 0.3s;

        &:hover {
            scale: 1.05;
        }
    } */
}





@media screen and (max-width: 500px) {
    .CheckAudio {
        width: 100%;
        padding: 70px 20px;
    
        .CheckAudioContent {
            width: 100%;
            height: auto;
            padding: 30px 10px;

            p {
                width: 80%;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 65px;
            }
            .btns {
                margin-top: 65px;
                gap: 20px;
    
                @include flex(flex, row, wrap, center, center, unset);
    
                button {
                    padding: 14px 50px;
                    border: 2px solid #2ECC71;
                    border-radius: 60px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #2ECC71;
                    background-color: transparent;
    
                    &.yes {
                        background: #2ECC71;
                        color: #fff;
                    }
                }
            }
            &.expand {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 50%;
                left: 50%;
                z-index: 10;
                transform: translate(-50%, -50%);
    
                .close {
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    cursor: pointer;
                    font-size: 30px;
                }
            }
        }
        .noSoundWrapper {
            width: 100%;
        }
        .noSound {
            padding: 20px;
        }
    }
}