@import "../../app/styles/globalStyles";



.ContactsPage {
    width: 100%;

    .breadcrumbs_ {
        width: 100%;
        padding: 20px 240px;
    }

    .contactUs {
        position: relative;
        height: 430px;
        margin-top: 170px;

        .content {
            width: 100%;
            height: 100%;
            padding: 20px 240px;

            @include flex(flex, row, nowrap, space-between, center, unset);

            .text {
                width: 495px;
                position: relative;
                z-index: 2;

                h1 {
                    font-family: "Gilroy";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 39px;
                    color: #FFFFFF;
                    margin-bottom: 38px;
                }
                p {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                }
            }
            .formWrapper {
                width: 912px;
                height: 691px;
                padding: 100px 50px;
                background: #2ECC71;
                border-radius: 7px;
                position: relative;
                z-index: 3;

                form {
                    @include flex(flex, column, nowrap, flex-start, center, unset);

                    gap: 50px;

                    .row {
                        width: 100%;
                        gap: 85px;

                        @include flex(flex, row, nowrap, flex-start, center, unset);

                        input,
                        textarea {
                            font-family: "Gilroy";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;

                            &::placeholder {
                                font-family: "Gilroy";
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                color: #ffffff8a;
                            }
                        }
                        input {
                            width: 370px;
                            height: 40px;
                            padding-left: 8px;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            border-bottom: 2px solid #FFFFFF;
                        }
                        textarea {
                            width: 100%;
                            height: 196px;
                            padding-left: 8px;
                            padding-top: 10px;
                            border: none;
                            outline: none;
                            border: 2px solid #FFFFFF;
                            border-radius: 6px;
                            background-color: transparent;
                        }
                    }
                    button {
                        width: 324px;
                        height: 40px;
                        border: none;
                        outline: none;
                        background-color: transparent;
                        border: 2px solid #FFFFFF;
                        border-radius: 60px;
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        

        .BG {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .map {
        width: 100%;
        height: 506px;
        padding: 20px 240px;
        margin-top: 290px;
        margin-bottom: 100px;

        .top {
            width: 100%;
            margin-bottom: 25px;
        }

        .top,
        .left,
        .right,
        .item {
            @include flex(flex, row, nowrap, space-between, center, unset);
        }
        .left {
            gap: 55px;
        }
        .item {
            .icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .right {
            gap: 20px;

            .icon {
                width: 30px;
                height: 30px;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .bottom {
            width: 100%;

            iframe {
                width: 100%;
            }
        }
    }
}







@media screen and (max-width: 500px) {
    .ContactsPage {
        width: 100%;

        .breadcrumbs_ {
            padding: 20px;
        }
        .contactUs {
            position: relative;
            height: auto;
            margin-top: 30px;

            .content {
                width: 100%;
                height: 100%;
                padding: 20px;

                @include flex(flex, column, nowrap, flex-start, center, unset);

                .text {
                    width: 100%;
                    margin-bottom: 20px;

                    h1 {
                        margin-bottom: 20px;
                    }
                }
                .formWrapper {
                    width: 100%;
                    height: auto;
                    padding: 30px;
                    border-radius: 10px;

                    form {
                        gap: 20px;

                        .row {
                            width: 100%;
                            gap: 20px;

                            @include flex(flex, column, nowrap, flex-start, center, unset);

                            input {
                                width: 100%;
                            }
                        }
                        button {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .map {
            width: 100%;
            height: auto;
            padding: 20px;
            margin-top: 30px;
            margin-bottom: 30px;

            .top {
                width: 100%;
                margin-bottom: 25px;
            }

            .top,
            .left,
            .right,
            .item {
                @include flex(flex, row, nowrap, space-between, center, unset);
            }
            .left {
                width: 100%;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;
            }
            .top {
                flex-direction: column;
                align-items: center;
            }
            .right {
                width: 45%;
                gap: 20px;
                align-items: flex-end;
            }
        }
    }
}