

.diploms {

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    &-left {
      max-width: 621px;

      &-title {
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #2ECC71;
        margin-bottom: 26px;

        &_margin {
          margin-bottom: 50px;
        }
      }

      &-desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #5C5C5C;
      }
    }

    &-right {
      display: flex;
      align-items: center;

      &-list {
        list-style: none;
        max-width: 290px;
        margin-left: 55px;

        li {
          display: flex;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          color: #000000;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0px;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #2ECC71;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &__wrapper {

    &-enroll {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;

      h5 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 8px;
      }
      .icon {
        width: 21px;
        height: 21px;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &-diploms {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}


@media screen and (max-width: 500px){
  .diploms {

    &__head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &-left {
        max-width: 621px;
        text-align: center;
        margin-bottom: 20px;

        &-title {
          font-weight: 700;
          font-size: 35px;
          line-height: 40px;
          color: #2ECC71;
          margin-bottom: 20px;

          &_margin {
            margin-bottom: 30px;
            text-align: center;
          }
        }

        &-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #5C5C5C;
        }
      }

      &-right {
        display: flex;
        align-items: center;

        img {
          width: 100px;
        }

        &-list {
          list-style: none;
          max-width: 290px;
          margin-left: 20px;

          li {
            display: flex;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0px;
            }

            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #2ECC71;
              margin-right: 8px;
            }
          }
        }
      }
    }

    &__wrapper {

      &-enroll {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        h5 {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 8px;
        }
        .icon {
          width: 21px;
          height: 21px;

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &-diploms {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
}