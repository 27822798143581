@import "../../../app/styles/globalStyles";



.SelectLang {
    position: relative;

    .Language_button {
        outline: none;
    }
    
    .headerLangDrop {
        position: absolute;
        width: fit-content;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        gap: 10px;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        button {
            background-color: transparent;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #535353;
            border: none;
            outline: none;

            &.selected {
                font-weight: 700;
                background: linear-gradient(180deg, #2ECC71 0%, #21C5EF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }
}