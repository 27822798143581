@import "../../../../app/styles/globalStyles";



.answers {
    width: 350px;
    gap: 19px;

    @include flex(flex, row, wrap, flex-start, center, unset);

    .questionTypeSelect {
        width: 100%;
        padding: 10px;
        border: none;
        outline: none;
        border-bottom: 1px solid #000000;
        cursor: pointer;

        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}







@media screen and (max-width: 500px) {
    .answers {
        width: 100%;
        gap: 10px;
    
        @include flex(flex, row, wrap, flex-start, center, unset);
    
        .questionTypeSelect {
            width: 100%;
            padding: 10px;
            border: none;
            outline: none;
            border-bottom: 1px solid #000000;
            cursor: pointer;
    
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }
    }
}