@import "../../../../app/styles/globalStyles";



.PieDiagramm {
    svg {
        width: 33px;
        height: 33px;
        transform: rotate(-90deg);
        fill: #2ECC71;
        border-radius: 50%;
    }
    circle {
        stroke: #D5D5D5;
        stroke-width: 100%;
    }
}