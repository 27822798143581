@import "../../app/styles/globalStyles";



.Studying {
    width: 100%;
    gap: 100px;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .StudyingSidebar_w {
        width: 378px;

        .StudyingSidebar {
            width: 100%;
            padding: 36px 0 43px 0;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
            gap: 10px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            .sidebarCourse {
                padding: 41px 32px;
                background: #2ECC71;
                border-radius: 10px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 30px;
                color: #FFFFFF;
                margin-bottom: 52px;
            }

            .changeCourse {
                padding: 5px 34px;
                background: #2ECC71;
                border: 2px solid #2ECC71;
                border-radius: 60px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }

        .StudyingSidebarMenu {
            width: 100%;
            gap: 30px;

            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            .ant-menu-submenu,
            .ant-menu-item {
                &>.ant-menu-title-content {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: #000000;
                }
                .ant-menu-submenu-title {
                    .ant-menu-title-content {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 30px;
                        color: #000000;
                    }
                }
                .ant-menu-sub {
                    .sidebarLessonCircle {
                        width: 23px;
                        height: 23px;
                        background: #D3D3D3;
                        border-radius: 34px;
        
                        @include flex(flex, row, nowrap, center, center, unset);
                    }
                    .ant-menu-item {
                        .ant-menu-title-content {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            color: #3A3A3A;
                        }
                        &.ant-menu-item-selected {
                            .ant-menu-title-content {
                                color: #fff;
                            }
                        }
                        
                        &.ant-menu-item-selected {
                            .sidebarLessonCircle {
                                background: #FFFFFF;
                            }
                        }
                    }
                }
            }
            .ant-menu-item,
            .ant-menu-submenu {
                width: 100%;
                border-radius: 0;
                margin: 0;

                .ant-menu-submenu-title {
                    width: 100%;
                    border-radius: 0;
                    margin: 0;
                }
                &.ant-menu-item-selected {
                    background: #2ECC71;
                    border-left: 7px solid #1E870D;

                    .ant-menu-title-content {
                        color: #fff;
                    }
                }
            }
            /* .ant-menu-item {
                .ant-menu-title-content {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: #000000;
                }
            } */
        }
    }

    .StudyingContent {
        width: 1442px;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);
    }

    .StudyingContent {
        width: 100%;
    }
}













@media screen and (max-width:500px) {
    .Studying {
        width: 100%;
        gap: 0;
        position: relative;
    
        @include flex(flex, row, nowrap, space-between, flex-start, unset);
    
        .StudyingSidebar_w {
            width: 0;
            position: relative;
    
            .StudyingSidebar {
                width: 80%;
                padding: 0;
                background-color: #fff;
                height: 100vh;
                box-shadow: 0px 8px 4px 3px rgba(0, 0, 0, 0.25);
                position: fixed;
                left: -80%;
                z-index: 10;
                transition: 0.3s;
                overflow-y: auto;
    
                .sidebarCourse {
                    margin-top: 52px;
                    margin-bottom: 30px;
                }
    
                .changeCourse {
                    margin-bottom: 150px;
                }

                .close {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 15px;
                    right: 15px;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                &.sidebarOpen {
                    left: 0;
                }
            }
    
            .StudyingSidebarMenu {
                width: 100%;
                gap: 30px;
    
                @include flex(flex, column, nowrap, flex-start, flex-start, unset);
    
                .ant-menu-submenu,
                .ant-menu-item {
                    &>.ant-menu-title-content {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 30px;
                        color: #000000;
                    }
                    .ant-menu-submenu-title {
                        .ant-menu-title-content {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 30px;
                            color: #000000;
                        }
                    }
                    .ant-menu-sub {
                        .sidebarLessonCircle {
                            width: 23px;
                            height: 23px;
                            background: #D3D3D3;
                            border-radius: 34px;
            
                            @include flex(flex, row, nowrap, center, center, unset);
                        }
                        .ant-menu-item {
                            .ant-menu-title-content {
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 24px;
                                color: #3A3A3A;
                            }
                            &.ant-menu-item-selected {
                                .ant-menu-title-content {
                                    color: #fff;
                                }
                            }
                            
                            &.ant-menu-item-selected {
                                .sidebarLessonCircle {
                                    background: #FFFFFF;
                                }
                            }
                        }
                    }
                }
                .ant-menu-item,
                .ant-menu-submenu {
                    width: 100%;
                    border-radius: 0;
                    margin: 0;
    
                    .ant-menu-submenu-title {
                        width: 100%;
                        border-radius: 0;
                        margin: 0;
                    }
                    &.ant-menu-item-selected {
                        background: #2ECC71;
                        border-left: 7px solid #1E870D;
    
                        .ant-menu-title-content {
                            color: #fff;
                        }
                    }
                }
                /* .ant-menu-item {
                    .ant-menu-title-content {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 30px;
                        color: #000000;
                    }
                } */
            }
        }

        .openSidebarBtn {
            width: 40px;
            height: 30px;
            position: absolute;
            top: 20px;
            left: 10px;

            @include flex(flex, column, nowrap, space-between, center, unset);

            div {
                width: 100%;
                height: 3px;
                border-radius: 3px;
                background-color: #000000;
            }
        }

        .StudyingContent {
            width: 100%;
            margin-top: 50px;
    
            @include flex(flex, column, nowrap, flex-start, flex-start, unset);
        }
    }
}