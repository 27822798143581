@import "../../app/styles/globalStyles";



.AskaquestionPage {
    width: 100%;
    margin-bottom: 100px;

    .breadcrumbs_ {
        width: 100%;
        padding: 20px 240px;
    }

    .wrapper {
        width: 100%;
        padding: 0 240px;

        .content {
            width: 100%;
            background: #20C4F3;
            border-radius: 5px;
            position: relative;
            padding: 42px;

            @include flex(flex, row, nowrap, space-between, center, unset);

            .img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                /* z-index: -1; */

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h1 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 900;
                font-size: 90px;
                line-height: 114px;
                color: #FFFFFF;
                position: relative;

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 72px;
                    color: #000000;
                    position: absolute;
                    right: -128px;
                    bottom: -42px;
                }
            }
            .form {
                width: 852px;
                padding: 30px;
                background: #2ECC71;
                border-radius: 11px;
                position: relative;
                z-index: 2;
                margin-bottom: 100px;

                gap: 25px;

                @include flex(flex, column, nowrap, flex-start, center, unset);

                .field {
                    width: 100%;

                    @include flex(flex, column, nowrap, flex-start, flex-start, unset);


                    .title {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                        padding-left: 23px;
                        margin-bottom: 10px;
                    }
                    input,
                    select {
                        width: 100%;
                        height: 41px;
                        background: #FAFAFA;
                        border-radius: 62px;
                        border: none;
                        outline: none;
                        padding-left: 23px;
                    }
                    .fieldImgContent {
                        width: 100%;
                        gap: 50px;

                        @include flex(flex, row, wrap, flex-start, center, unset);
                    }
                    .addImg {
                        width: 32px;
                        height: 32px;
                        border: none;
                        outline: none;
                        background-color: transparent;
                        margin-left: 20px;

                        svg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .submitBtn {
                    padding: 15px 35px;
                    border: 2px solid #FFFFFF;
                    border-radius: 57px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    background-color: transparent;
                }
                
                .QuestionSaved,
                .QuestionDontSaved {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FFFFFF;
                }
                .QuestionDontSaved {
                    color: #FF2323;
                }
            }
        }
    }
}








@media screen and (max-width: 500px) {
    .AskaquestionPage {
        width: 100%;
    
        .breadcrumbs_ {
            width: 100%;
            padding: 20px;
        }
    
        .wrapper {
            width: 100%;
            padding: 20px;
    
            .content {
                border-radius: 10px;
                padding: 15px;
    
                @include flex(flex, column, nowrap, flex-start, center, unset);
    
                .img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                h1 {
                    font-size: 90px*0.4;
                    line-height: 114px*0.4;
    
                    span {
                        font-size: 60px*0.4;
                        line-height: 72px*0.4;
                        right: -128px*0.4;
                        bottom: -42px*0.4;
                    }
                }
                .form {
                    width: 100%;
                    padding: 20px;
                    margin: 42px 0;
                }
            }
        }
    }
}