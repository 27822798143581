@import "../../../app/styles/globalStyles";

.Header {
  width: 100%;
  position: fixed;
  z-index: 10;
  padding: 13px 240px;
  background: #ffff;
  height: 64px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @include flex(flex, row, nowrap, center, center, unset);

  .Header_content {
    width: 100%;
    position: relative;

    @include flex(flex, row, nowrap, space-between, center, unset);

    .Logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .Header_left {

      .Menu_button {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #282828;

        .Menu_icon {
          margin-right: 12px;
        }
      }
    }

    .Header_right {
      @include flex(flex, row, nowrap, space-between, center, unset);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      .Header_right_left {
        width: 150px;
        margin-right: 66px;
        @include flex(flex, row, nowrap, space-between, center, unset);
      }

      .Header_right_right {
        @include flex(flex, row, nowrap, space-between, center, unset);
        width: 235px;


        .Language_button {
          width: 25px;
          height: 25px;
          background: transparent;
          border: none;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .Header_signInUp {
          border: 2px solid #2ECC71;
          border-radius: 60px;
          width: 195px;
          padding: 10px 20px;
          display: flex;
          justify-content: space-around;

          & * {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #2ECC71;
          }

          .loggedUser {
            border: none;
            outline: none;
            background-color: transparent;
            gap: 10px;

            @include flex(flex, row, nowrap, space-around, center, unset);

            .avatar {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .SignIn {
            background: transparent;
            border: none;
            
            &:hover {
              color: #282828;
            }
          }

          .slash {
            color: #2ECC71;
          }

          .SignUp {
            background: transparent;
            border: none;

            &:hover {
              color: #282828;
            }
          }
        }
        .Header_signInUpMobile {
          display: none;
        }
      }


    }
  }
}

.Header_dropdown_wrapper {
  padding: 160px 415px 20px 240px;
}

.Header_dropdown {

  width: 100%;
  height: calc(100vh - 64px);
  position: fixed;
  margin-top: 64px;
  z-index: 9;

  transform: translateY(-240%);
  filter: blur(10px);
  transition: all 0.3s;
  background: transparent;

  &.active {
    transform: translateY(0);
    filter: blur(0);
    background: #0C0C0C;
    background-image: url("../img/headerMenuBG.png");
    overflow-y: auto;
  }

  .Dropdown_footer {

    @include flex(flex, row, nowrap, space-between, flex-start, unset);
    margin-top: 77px;
    .Dropdown_footer_left {

      .social {
        background: transparent;
        border: none;
        margin-right: 20px;
      }
    }

    .Dropdown_footer_right {

      .Footer_right_desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #C4C4C4;
        letter-spacing: 0.02em;
        margin-left: 30px;
        transition: all 0.3s;

        &:hover {

          color: #FFFFFF;
        }
      }
    }
  }

  .Dropdown_content {
    height: 100%;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .Content_left {

      .Left_link {
        margin-bottom: 50px;
        display: flex;

        &:hover {
          .Left_link_desc {
            color: #DDDDDD;
          }

          .Left_link_number {
            color: #FFFFFF;
          }
        }

        .Left_link_number {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #DDDDDD;
          margin-right: 12px;
          transition: all 0.3s;

        }

        .Left_link_desc {
          font-weight: 700;
          font-size: 40px;
          line-height: 50px;
          color: #FFFFFF;
          transition: all 0.3s;

        }
      }
    }

    .Content_right {

      .Content_right_dil {
        @include flex(flex, column, nowrap, space-between, flex-start, unset);

        .Content_right_desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #BFBFBF;
          margin-bottom: 39px;
        }

        .Right_link_desc {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          border-bottom: 1px solid #FFFFFF;
          color: #FFFFFF;
          margin-bottom: 35px;
          transition: all 0.3s;

          &:hover {

            color: #BFBFBF;
            border: none;
          }
        }
      }

      .Content_right_contacts {
        @include flex(flex, column, nowrap, space-between, flex-start, unset);
        .Content_right_desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #BFBFBF;
          margin-bottom: 39px;
          margin-top: 78px;
        }

        .Content_right_phone {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #FFFFFF;
          margin-bottom: 35px;
        }

        .Content_right_email {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #FFFFFF;
        }
      }
    }
  }
}


















@media screen and (max-width: 500px) {

  .Header {
    width: 100%;
    padding: 10px;

    .Header_content {
      position: relative;

      .Header_right {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        .Header_right_left {
          display: none;
        }

        .Header_right_right {
          width: 75px;

          .Language_button {
            width: 25px;
            height: 25px;
            background: transparent;
            border: none;
          }

          .Header_signInUp {
            display: none;
          }
          .Header_signInUpMobile {
            position: absolute;
            right: 0;
            top: 50px;
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            gap: 10px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            button {
              border: 2px solid #2ECC71;
              border-radius: 60px;
              padding: 5px 10px;
              background-color: transparent;
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #2ECC71;
            }
          }
        }
      }
    }
  }



  .Header_dropdown {
    width: 100%;
    height: calc(100% - 64px);
    position: fixed;
    margin-top: 64px;
    z-index: 9;

    margin-bottom: 100px;
    transform: translateY(-200%);
    filter: blur(10px);
    transition: all 0.3s;
    background: transparent;
    overflow-y: auto;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    .Header_dropdown_wrapper {
      padding: 20px;
    }

    &.active {
      transform: translateY(0);
      filter: blur(0);
      background: #0C0C0C;
      background-image: url("../img/headerMenuBG.png");
    }

    .Dropdown_footer {
      @include flex(flex, column, nowrap, flex-start, center, unset);
      gap: 30px;
      margin-top: 20px;
      padding-bottom: 20px;

      .Dropdown_footer_left {
        .social {
          background: transparent;
          border: none;
          margin-right: 20px;
        }
      }

      .Dropdown_footer_right {
        .Footer_right_desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #C4C4C4;
          letter-spacing: 0.02em;
          margin-left: 30px;
        }
      }
    }

    .Dropdown_content {
      height: 100%;

      @include flex(flex, column, nowrap, flex-start, center, unset);

      .Content_left {
        margin-top: 30px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        .Left_link {
          margin-bottom: 20px;
          display: flex;

          .Left_link_number {
            display: none;
          }

          .Left_link_desc {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .Content_right {


        .Content_right_dil {
          @include flex(flex, column, nowrap, flex-start, center, unset);

          .Content_right_desc {
            font-size: 16px;
            line-height: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
          }

          .Right_link_desc {
            font-size: 20px;
            line-height: 24px;
            border-bottom: 1px solid #FFFFFF;
            margin-bottom: 20px;
          }
        }

        .Content_right_contacts {
          @include flex(flex, column, nowrap, space-between, center, unset);

          .Content_right_desc {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
            margin-top: 20px;
          }

          .Content_right_phone {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 20px;
          }

          .Content_right_email {
            font-size: 24px;
            line-height: 29px;
          }
        }
      }
    }


  }
}