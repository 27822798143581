

.course-info {
  padding: 33px 240px 100px 0;
  width: 100%;

  &__content1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 117px;

    &-desc {
      max-width: 677px;

      h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #2ECC71;
        margin-bottom: 61px;
      }

      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #494949;
      }
    }
  }
}

@media screen and (max-width: 500px){
  .course-info {
    padding: 20px 20px 20px 10px;
    margin-bottom: 50px;

    &__content1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 50px;

      &-desc {
        max-width: 677px;
        margin-bottom: 20px;

        h1 {
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #2ECC71;
          margin-bottom: 30px;
        }

        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #494949;
        }
      }
    }
  }
}