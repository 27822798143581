@import "../../app/styles/globalStyles";


.certificate_wrapper {

  .certificate_breadcrumbs {
    padding: 50px 240px 20px 240px;
  }

  .certificate_bg {
    background-image: url("../../shared/ui/img/certificatePageBg.png");
    height: 672px;
    display: flex;
    align-items: center;
    padding-left: 240px;

    &_content {
      display: flex;
      flex-direction: column;
      max-width: 664px;
      margin-right: 314px;

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #FFFFFF;
        margin-bottom: 33px;
      }

      &_desc {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #EDEDED;
        margin-bottom: 42px;
      }

      &_button {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        background: transparent;
        padding: 10px 35px;
        width: 205px;
        border: 2px solid #FFFFFF;
        border-radius: 60px;
      }
    }
  }

  .certificate_content {
    padding: 100px 240px;

    .certificate_content1 {
      margin-bottom: 150px;

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        color: #424242;
        margin-bottom: 100px;

        span {
          color: #2ECC71;
        }
      }

      &_container {
        display: flex;
        align-items: center;
        justify-content: center;

        &_list {
          list-style: none;
          margin-left: 175px;
          max-width: 340px;

          li {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .certificate_content2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_container {
        max-width: 796px;

        &_title {
          font-weight: 700;
          font-size: 36px;
          line-height: 45px;
          color: #424242;
          margin-bottom: 78px;

          span {
            color: #18AC00;
          }
        }

        &_desc {
          font-weight: 500;
          font-size: 18px;
          line-height: 34px;
          color: #5C5C5C;
        }
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .certificate_wrapper {

    .certificate_breadcrumbs {
      padding: 20px;
    }

    .certificate_bg {
      background-image: url("../../shared/ui/img/certificatePageBg.png");
      height: 672px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      &_img {
        img {
          width: 150px;
        }
      }

      &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;

        &_title {
          font-weight: 700;
          font-size: 30px;
          line-height: 45px;
          color: #FFFFFF;
          margin-bottom: 25px;
        }

        &_desc {
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          color: #EDEDED;
          margin-bottom: 42px;
        }

        &_button {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          background: transparent;
          padding: 10px 35px;
          width: 205px;
          border: 2px solid #FFFFFF;
          border-radius: 60px;
        }
      }
    }

    .certificate_content {
      padding: 20px;

      .certificate_content1 {
        margin-bottom: 70px;

        &_title {
          font-weight: 700;
          font-size: 24px;
          line-height: 40px;
          text-align: center;
          color: #424242;
          margin-bottom: 50px;

          span {
            color: #2ECC71;
          }
        }

        &_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 250px;
          }

          &_list {
            list-style: none;
            margin-left: 0px;
            max-width: 340px;
            margin-top: 20px;

            li {
              font-weight: 700;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              display: flex;
              align-items: center;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .certificate_content2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        img {
          width: 300px;
        }

        &_container {
          max-width: 796px;
          margin-bottom: 30px;

          &_title {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: #424242;
            margin-bottom: 50px;

            span {
              color: #18AC00;
            }
          }

          &_desc {
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: #5C5C5C;
          }
        }
      }
    }
  }
}