@import "../../../app/styles/globalStyles";



.ExamPage {
    width: 100%;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    .examInfo {
        width: 100%;
        margin-bottom: 150px;

        @include flex(flex, row, nowrap, space-between, flex-start, unset);

        .left {
            width: 780px;

            h2 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                color: #2ECC71;
                margin-bottom: 50px;
            }
            p {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: #5C5C5C;
                margin-bottom: 50px;
            }
            .contactInfo {
                width: 100%;
                gap: 80px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                .item {
                    width: fit-content;

                    @include flex(flex, row, nowrap, flex-start, center, unset);

                    .icon {
                        width: 24px;
                        height: 30px;
                        margin-right: 10px;

                        svg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    span {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 151.65%;
                        color: #000000;
                    }
                }
            }
        }
        .right {
            width: 590px;

            .map {
                width: 588px;
                height: 402px;
                box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
                border-radius: 32px;
                overflow: hidden;

                iframe {
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }
    .enrollToExamWrapper {
        width: 953px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        .enrollToExamTitle {
            margin-bottom: 100px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            h5 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #000000;
                margin-bottom: 8px;
            }
            .icon {
                width: 21px;
                height: 21px;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .choozeExamType,
        .choozeExamDate,
        .choozeExamTime {
            width: 100%;
            margin-bottom: 30px;

            .formTitle {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #2ECC71;
                margin-bottom: 15px;
            }
        }
        .choozeExamType {
            .examTypeSelect {
                width: 100%;
                height: 59px;
                outline: none;
                border: 2px solid #5A5A5A;
                border-radius: 6px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #373737;
                padding-left: 20px;
                cursor: pointer;
            }
        }
        .choozeExamDate {
            .calendarWrapper {
                .examCalendar {
                    width: 100%;
                    border: 2px solid #000000;
                    border-radius: 20px;

                    .react-calendar__navigation {
                        width: 450px;
                        margin: 28px auto;

                        .react-calendar__navigation__label__labelText {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 45px;
                            color: #000000;
                        }
                        .react-calendar__navigation__prev-button,
                        .react-calendar__navigation__next-button,
                        .react-calendar__navigation__prev2-button,
                        .react-calendar__navigation__next2-button {
                            min-width: auto;
                            width: 25px;
                            height: 25px;
                            background: #2ECC71;
                            border-radius: 50%;
                            margin: auto 10px;
                            font-size: 30px;
                            
                            @include flex(flex, row, nowrap, center, center, unset);
                        }
                    }
                    .react-calendar__month-view__weekdays {
                        border-bottom: 2px solid #000000;
                        padding: 5px 0;

                        .react-calendar__month-view__weekdays__weekday {
                            abbr {
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 29px;
                                color: #000000;
                                text-decoration: none;
                            }
                        }
                    }
                    .react-calendar__month-view__days {
                        padding:  22px 0;
                        justify-content: center;
                        gap: 35px 96px;

                        .react-calendar__tile {
                            flex: none !important;
                            min-width: 0;
                            width: 40px;
                            height: 40px;
                            background: #2ECC71;
                            border-radius: 50%;

                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            color: #000000;

                            &.react-calendar__month-view__days__day--neighboringMonth {
                                background: transparent;
                                color: #949494;
                            }
                            &.react-calendar__tile--active {
                                transform: scale(1.1);
                                border: 2px solid #000;

                                &:disabled {
                                    opacity: 0.5;
                                    transform: scale(1);
                                    border: none;
                                }
                            }
                        }
                        .free {
                            opacity: 1;
                        }
                        &[disabled] {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .choozeExamTime {
            .timeWrapper {
                @include flex(flex, row, nowrap, flex-start, center, unset);

                gap: 30px;

                .timeBtn {
                    padding: 12px 21px;
                    background-color: transparent;
                    border: 2px solid #000000;
                    border-radius: 9px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;

                    &.busy {
                        border-color: #A8A8A8;
                        color: #A8A8A8;
                    }
                    &.selected {
                        border-color: #2ECC71;
                    }
                }
            }
        }
        .enrollToExamBtn {
            padding: 15px 125px;
            background: #2ECC71;
            border: 2px solid #FFFFFF;
            border-radius: 91px;
            margin-top: 50px;

            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
        }
        .enrollUserError {
            margin-top: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #ff0000;
        }
    }
}



@media screen and (max-width: 500px) {
    .ExamPage {
        .examInfo {
            width: 100%;
            margin-bottom: 80px;
    
            @include flex(flex, column, nowrap, space-between, flex-start, unset);
    
            .left {
                width: 100%;
                margin-bottom: 30px;
    
                h2 {
                    font-size: 40px*0.8;
                    line-height: 50px*0.8;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 20px;
                }
                .contactInfo {
                    width: 100%;
                    gap: 20px;
    
                    @include flex(flex, row, wrap, flex-start, center, unset);
    
                    .item {
                        .icon {
                            width: 24px;
                            height: 30px;
                        }
                        span {
                            font-size: 20px*0.8;
                        }
                    }
                }
            }
            .right {
                width: 100%;
    
                .map {
                    width: 100%;
                    height: 350px;

                    iframe {
                        border-radius: 32px;
                        overflow: hidden;
                    }
                }
            }
        }
        .enrollToExamWrapper {
            width: 100%;
    
            .enrollToExamTitle {
                margin-bottom: 80px;
            }
    
            .choozeExamType,
            .choozeExamDate,
            .choozeExamTime {
                width: 100%;
                margin-bottom: 30px;
    
                .formTitle {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
            }
            .choozeExamDate {
                .calendarWrapper {
                    .examCalendar {
                        width: 100%;
                        border: 2px solid #000000;
                        border-radius: 20px;
    
                        .react-calendar__navigation {
                            width: 100%;
                            margin: 20px auto;
    
                            .react-calendar__navigation__label__labelText {
                                font-size: 36px*0.6;
                                line-height: 45px*0.6;
                            }
                            .react-calendar__navigation__prev-button,
                            .react-calendar__navigation__next-button,
                            .react-calendar__navigation__prev2-button,
                            .react-calendar__navigation__next2-button {
                                font-size: 25px;
                                color: #000;
                            }
                        }
                        .react-calendar__month-view__weekdays {
                            padding: 0;
    
                            .react-calendar__month-view__weekdays__weekday {
                                abbr {
                                    font-size: 24px*0.6;
                                    line-height: 29px*0.6;
                                }
                            }
                        }
                        .react-calendar__month-view__days {
                            padding: 20px 0;
                            gap: 80px*$TELvw*0.19 96px*$TELvw*0.19;
    
                            .react-calendar__tile {
                                width: 40px*$TELvw*0.7;
                                height: 40px*$TELvw*0.7;
                                font-size: 18px*$TELvw*0.7;
                                line-height: 22px*$TELvw*0.7;
                            }
                        }
                    }
                }
            }
            .choozeExamTime {
                .timeWrapper {
                    @include flex(flex, row, wrap, flex-start, center, unset);

                    .timeBtn {
                        padding: 12px*0.9 21px*0.9;
                        border-radius: 9px*0.9;
                        font-size: 18px*0.9;
                        line-height: 22px*0.9;
                    }
                }
            }
            .enrollToExamBtn {
                padding: 15px*0.8 80px*0.8;
                border-radius: 91px*0.8;
                margin-top: 30px*0.8;
                font-size: 18px*0.8;
                line-height: 22px*0.8;
            }
        }
    }
}