@import "../../../app/styles/globalStyles";



.coverImg {
    width: 200px;
    height: 150px;
    margin-bottom: 1px;
    position: relative;

    @include flex(flex, row, nowrap, flex-start, center, unset);

    .img {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 10px;
        background-color: #000000cc;
        overflow: hidden;

        @include flex(flex, row, nowrap, center, center, unset);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        svg {
            width: 50%;
            height: 50%;
            object-fit: contain;
        }
        input {
            display: none;
        }
        label {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            cursor: pointer;
        }
        .noMediafile {
            gap: 10px;

            @include flex(flex, row, nowrap, center, center, unset);

            svg {
                width: 19px;
                height: 19px;

                fill: #fff;
            }
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #fff;
            }
        }
    }
    .imgBtns {
        width: fit-content;
        min-width: fit-content;
        max-width: fit-content;
        margin-left: 17px*$vw;
        position: absolute;
        right: -25px;
        z-index: 2;

        @include flex(flex, row, nowrap, flex-start, center, unset);

        button {
            width: 17px*$vw;
            height: 17px*$vw;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    
}