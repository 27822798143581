@import "../../../../app/styles/globalStyles";



.students-search {
  width: 100%;
  background:#2ECC71;
  border-radius: 18px;
  padding: 31px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .searchForm {
    width: 100%;

    @include flex(flex, row, nowrap, flex-start, flex-end, unset);

    gap: 45px;

    .item {
      @include flex(flex, column, nowrap, flex-start, flex-start, unset);

      width: 214px;

      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        padding: 10px;
        background: #FFFFFF;
        border-radius: 40px;

        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }


    }

    &__button {
      border: 2px solid #FFFFFF;
      border-radius: 60px;
      padding: 10px;
      background: transparent;
      width: 195px;

      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}


@media screen and (max-width: 500px){
  .students-search {
    width: 100%;
    background:#2ECC71;
    border-radius: 18px;
    padding: 15px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .searchForm {
      width: 100%;

      @include flex(flex, row, wrap, center, center, unset);

      gap: 10px;
      margin-bottom: 20px;

      .item {
        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        width: 100%;

        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 10px;
        }
        input {
          width: 100%;
          border: none;
          outline: none;
          padding: 10px;
          background: #FFFFFF;
          border-radius: 40px;

          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }


      }

      &__button {
        border: 2px solid #FFFFFF;
        border-radius: 60px;
        padding: 10px;
        background: transparent;
        width: 195px;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }
  }
}