

.cours-card {
  background-image: url("../../../../shared/ui/img/UserCabinet/coursCardBg.png");
  max-width: 1117px;
  min-width: 1117px;
  background-size: cover;
  background-position: center;
  display: flex;
  padding: 46px 25px;
  border-radius: 30px;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__left {
    margin-left: 70px;
    margin-right: 105px;
    position: relative;

    span {
      position: absolute;
      left: 50%;
      margin-left: -40px;
      top: 50%;
      margin-top: -50px;
      font-weight: 700;
      font-size: 70px;
      line-height: 87px;
      color: #FFFFFF;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;
      color: #000000;
      margin-bottom: 21px;
    }

    &-desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 34px;
      color: #FFFFFF;
      margin-bottom: 11px;
    }

    &-button {
      min-width: 195px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #2ECC71;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 60px;
      padding: 10px;

      &-container {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-inprogress {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &-content {
        max-width: 497px;
      }

      &-result {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-result {
          width: 175px;
          height: 175px;
          position: relative;

          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 900;
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: 0.1em;
          }

          &-border {
            position: absolute;
            box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.5);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 93%;
            height: 93%;
            border-radius: 50%;
            z-index: 1;
          }

          svg {
            width: 100%;
            height: 100%;
            position: relative;


            circle {
              width: 100%;
              height: 100%;
              stroke-width: 3.5px;
            }

          }

          .circle-progress {
            stroke: #FFFFFF;
            z-index: 5;
            position: absolute;
          }

          .circle-background {
            stroke: #21C5EF;
            fill: #21C5EF;

          }
        }
      }
    }
  }
}


@media screen and (max-width: 500px){
  .cours-card {
    background-image: url("../../../../shared/ui/img/UserCabinet/coursCardBg.png");
    max-width: 1117px;
    min-width: 0;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }

    &__left {
      margin-left: 0px;
      margin-right: 0px;
      position: relative;

      span {
        position: absolute;
        left: 50%;
        margin-left: -35px;
        top: 50%;
        margin-top: -50px;
        font-weight: 700;
        font-size: 70px;
        line-height: 87px;
        color: #FFFFFF;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        color: #000000;
        margin-bottom: 10px;
      }

      &-desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 11px;
        text-align: center;
      }

      &-button {
        min-width: 195px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #2ECC71;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 60px;
        padding: 10px;

        &-container {
          display: flex;
          justify-content: flex-end;
        }
      }

      &-inprogress {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;

        &-content {
          max-width: 497px;
          text-align: center;
          margin-bottom: 10px;
        }

        &-result {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          &-result {
            width: 175px;
            height: 175px;
            position: relative;
            margin-bottom: 10px;

            .number {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 900;
              font-size: 24px;
              color: #FFFFFF;
              letter-spacing: 0.1em;
            }

            &-border {
              position: absolute;
              box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.5);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 93%;
              height: 93%;
              border-radius: 50%;
              z-index: 1;
            }

            svg {
              width: 100%;
              height: 100%;
              position: relative;


              circle {
                width: 100%;
                height: 100%;
                stroke-width: 3.5px;
              }

            }

            .circle-progress {
              stroke: #FFFFFF;
              z-index: 5;
              position: absolute;
            }

            .circle-background {
              stroke: #21C5EF;
              fill: #21C5EF;

            }
          }
        }
      }
    }
  }
}