@import "../../../../app/styles/globalStyles";



.AnswerItem {
    width: 100%;
    /* height: 50px; */
    padding: 15px;
    background: #E9E9E9;
    border-radius: 7px;
    cursor: pointer;

    @include flex(flex, row, nowrap, flex-start, center, unset);

    &.viewType2 {
        width: fit-content;
        padding: 15px;
        background: #E9E9E9;
        border-radius: 4px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 147%;
        color: #000000;

        .radio,
        .checkbox {
            display: none;
        }
        span {
            margin: 0;
        }
    }

    &.checked {
        background-color: #21C5EF;

        span {
            color: #FFFFFF;
        }

        .radio {
            border: 3px solid #FFFFFF;
        }
        .checkbox {
            border: 1px solid #FFFFFF;

            .checkMark {
                display: flex;
            }
        }
    }

    .radio {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #000000;
    }
    .checkbox {
        width: 15px;
        height: 15px;
        border: 1px solid #000000;
        border-radius: 1px;
        position: relative;

        .checkMark {
            width: 15px;
            height: 15px;
            position: absolute;
            top: -4px;
            right: -4px;

            @include flex(flex, row, nowrap, center, center, unset);

            display: none;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    span {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-left: 13px;
    }
}








@media screen and (max-width: 500px) {
    .AnswerItem {
        width: 100%;
        height: 50px;
        padding: 15px;

        span {
            font-size: 20px*0.8;
            line-height: 24px*0.8;
        }
    }
}