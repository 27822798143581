@import "../../../app/styles/globalStyles";



.online-lesson {
  width: 100%;
  padding: 33px 240px 100px 0;

  @include flex(flex, row, nowrap, space-between, flex-start, unset);


  &__content-w {
    width: 1200px;

    .OnlineLessonHeader {
      width: 100%;
      padding-bottom: 23px;

      @include flex(flex, row, nowrap, space-between, center, unset);


      .title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
      }
      .createLesson {
        border: none;
        outline: none;
        background-color: transparent;
        gap: 13px;

        @include flex(flex, row, nowrap, flex-start, center, unset);

        span {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #666666;
        }
        .icon {
          width: 31px;
          height: 16px;

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .OnlineLessonContent {
      width: 100%;
      padding-right: 2px;

      @include flex(flex, column, nowrap, flex-start, flex-start, unset);

      .pastLessons {
        width: 100%;
        min-height: 280px;
        overflow-x: auto;
        gap: 66px;

        @include flex(flex, row, nowrap, flex-start, flex-start, unset);

        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }
      }
      .futureLessons {
        width: 100%;
        margin-top: 82px;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        .title {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 45px;
          color: #000000;
          margin-bottom: 40px;
        }
        .futureLessons_content {
          width: 100%;
          padding: 5px;
          gap: 30px;
          max-height: 700px;
          overflow-y: auto;

          @include flex(flex, column, nowrap, flex-start, flex-start, unset);

          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.25);
            cursor: pointer;
          }
        }
      }
    }
  }
}





@media screen and (max-width: 500px) {
  .online-lesson {
    width: 100%;
    padding: 20px 20px 20px 10px;
    margin-bottom: 50px;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    &__content-w {
      width: 100%;
      margin-top: 40px;

      .OnlineLessonHeader {
        padding-bottom: 23px;

        .title {
          font-size: 36px*0.6;
          line-height: 45px*0.6;
        }
        .createLesson {
          border: none;
          outline: none;
          background-color: transparent;
          gap: 5px;

          @include flex(flex, row, nowrap, flex-start, center, unset);

          span {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
          }
          .icon {
            width: 20px;
            height: 16px;

            svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .OnlineLessonContent {
        width: 100%;

        .pastLessons {
          width: 100%;
          min-height: 220px;
          gap: 20px;
        }
        .futureLessons {
          width: 100%;
          margin-top: 40px;

          .title {
            font-size: 36px*0.7;
            line-height: 45px*0.7;
            margin-bottom: 20px;
          }
          .futureLessons_content {
            gap: 20px;
            max-height: 700px;
          }
        }
      }
    }
  }
}