

.registration-form {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: 998;

  &_active {
    opacity: 1;
    pointer-events: all;
  }



  &__content {
    max-height: 90%;
    padding: 27px 30px;
    border-radius: 11px;
    background: #FFFFFF;
    max-width: 504px;
    width: 95%;
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
      height: 0px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 48px;
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
    }
  }
}

@media screen and (max-width: 500px){
  .registration-form {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: 998;

    &_active {
      opacity: 1;
      pointer-events: all;
    }



    &__content {
      padding: 10px;
      border-radius: 11px;
      background: #FFFFFF;
      max-width: 504px;
      position: relative;



      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: none;
      }
    }
  }
}