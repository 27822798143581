@import "../../../../app/styles/globalStyles";



#progressInput {
    margin: 20px auto;
    width: 30%;
}

.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: red;
}