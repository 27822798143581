@import "../../../../app/styles/globalStyles";



.PracticLessonTest {
    width: 100%;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    .top {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 10px 36px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #434343;
        margin-bottom: 15px;
    }
    .questionContent {
        width: 100%;
        padding: 20px 36px;
        background: #FFFFFF;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;

        .text {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            margin-bottom: 25px;
        }
        .answers {
            width: 859px;
            margin: 0 auto;

            .AnswerItem {
                width: 420px;
            }
        }
    }
    .btns {
        width: 100%;
        margin-top: 27px;
        gap: 48px;

        @include flex(flex, row, nowrap, flex-end, center, unset);

        button {
            padding: 12px 27px;
            background: #2ECC71;
            border-radius: 60px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            border: none;
            outline: none;
        }
    }
}






@media screen and (max-width: 500px) {
    .PracticLessonTest {
        width: 100%;
    
        @include flex(flex, column, nowrap, flex-start, center, unset);
    
        .top {
            width: 100%;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 10px 36px;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 15px;
        }
        .questionContent {
            width: 100%;
            padding: 20px 36px;
            background: #FFFFFF;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
    
            .text {
                font-size: 20px;
                line-height: 25px;
                margin-bottom: 25px;
            }
            .answers {
                width: 100%;
                margin: 0 auto;
    
                .AnswerItem {
                    width: 420px;
                }
            }
        }
        .btns {
            width: 100%;
            margin-top: 27px;
            gap: 10px;

            @include flex(flex, row, wrap, flex-start, center, unset);

            button {
                padding: 12px 27px;
                border-radius: 60px;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}