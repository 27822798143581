@import "../../../app/styles/globalStyles";

.footer {
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../img/footerBG.png");
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  &_content {
    @include flex(flex, row, nowrap, space-between, flex-start, unset);
    padding: 100px 246px 0 246px;

    &_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2ECC71;
        margin-bottom: 40px;
      }

      &_desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-bottom: 20px;
        transition: all 0.3s;

        &:hover {
          color: #C4C4C4;
        }
      }
    }
  }

  &_row {
    display: flex;
    justify-content: space-between;
    margin: 0 240px;
    border-top: 1px solid #595959;
    padding: 16px 0;



    &_left {
      display: flex;

      &_inc {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-right: 107px;
      }

      &_desc {


        &_link {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          letter-spacing: 0.02em;
          color: #C4C4C4;
          margin-right: 30px;
          transition: all 0.3s;

          &:hover {
            color: #FFFFFF;
          }
        }
      }
    }

    &_right {
      display: flex;
      align-items: center;

      &_img {
        margin-right: 20px;
        border: none;
        background: transparent;
      }
    }
  }
}

.language {
  width: 161px;
  height: 41px;


  select {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    outline: none;
    background-color: transparent;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 8px;

    option {
      background-color: black;
    }

  }
}

@media screen and (max-width: 500px) {
  .footer {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../img/footerBG.png");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    &_content {
      @include flex(flex, row, wrap, space-between, flex-start, unset);
      padding: 20px;

      &_column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;

        &_title {
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #2ECC71;
          margin-bottom: 20px;
        }

        &_desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #FFFFFF;
          margin-bottom: 20px;
        }
      }
    }

    &_row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 20px;
      border-top: 1px solid #595959;
      padding: 16px 0;
      align-items: center;



      &_left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &_inc {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #FFFFFF;
          margin-right: 10px;
        }

        &_desc {
          display: flex;
          flex-direction: column;
          align-items: flex-end;


          &_link {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            letter-spacing: 0.02em;
            color: #C4C4C4;
            margin-right: 0;
          }
        }
      }

      &_right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;


        &_img {
          margin-right: 10px;
          border: none;
          background: transparent;
        }
      }
    }
  }

  .language {
    width: 161px;
    height: 41px;
    margin-top: 10px;


    select {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      outline: none;
      background-color: transparent;

      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      padding: 8px;

      option {
        background-color: black;
      }

    }
  }
}