@import "../../../../app/styles/globalStyles";



.timerNumbers {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2ECC71;
}