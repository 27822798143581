@import "../../../../app/styles/globalStyles";

.singleAnswer_content {
  padding: 30px 240px 0 240px;

  &:last-child {
    padding-bottom: 100px;
  }


  &_answer {
    padding: 25px;
    background: #F2F2F2;
    box-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    &_header {

      border-bottom: 0.5px solid #3E3D3D;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      padding-bottom: 15px;

      &_content {

        &_desc {
          display: flex;


          &_row {
            display: flex;
            align-items: center;
            margin-right: 30px;

            span {
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }
      }
    }

    &_content {
      margin-bottom: 40px;
      transition: 0.3s;


      &_hidden {
        max-height: 24px;
        overflow-y: hidden;
      }

      img {
        width: 700px;
        margin: 10px;
      }




      &_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 25px;
      }

      &_desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #D4D4D4;
        margin-bottom: 25px;
        margin-left: 20px;
        margin-right: 90px;
        background: #000000;
        border-radius: 4px;
        padding: 10px 20px;

        &_text {
          margin-bottom: 30px;
        }

        &_hide {
          max-height: 64px;
          overflow: hidden;
        }

      }
    }

    &_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_hide {
        display: none;
      }

      &_title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        margin-bottom: 30px;
        margin-top: 100px;

        span {
          font-weight: 900;
          color: #18AC00;
        }
      }

      &_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_cancelButton {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #18AC00;
        border: 2px solid #18AC00;
        border-radius: 57px;
        padding: 15px 55px;
        margin-right: 43px;
      }

      &_submitButton {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #18AC00;
        border-radius: 57px;
        border: none;
        padding: 15px 50px;

      }
    }
  }
}


@media screen and (max-width: 500px) {
  .singleAnswer_content {
    padding: 10px;

    &:last-child {
      padding-bottom: 50px;
    }


    &_answer {
      padding: 15px;
      background: #F2F2F2;
      box-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      &_header {

        border-bottom: 0.5px solid #3E3D3D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 5px;

        &_content {
          margin-bottom: 10px;

          &_desc {
            display: flex;


            &_row {
              display: flex;
              align-items: center;
              margin-right: 15px;

              span {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #000000;
                margin-left: 5px;
              }
            }
          }
        }
      }

      &_content {
        margin-bottom: 40px;
        transition: 0.3s;

        img {
          width: 250px;
          margin: 10px;
        }

        &_title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 20px;
        }

        &_desc {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #D4D4D4;
          margin-bottom: 25px;
          margin-left: 10px;
          margin-right: 40px;
          background: #000000;
          border-radius: 4px;
          padding: 5px 10px;

          &_hide {
            max-height: 60px;
            overflow: hidden;
          }

          img {
            margin-top: 20px;
            max-width: 250px;
          }
        }
      }

      &_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &_form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_hide {
          display: none;
        }

        &_title {
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          color: #000000;
          margin-bottom: 30px;
          margin-top: 60px;

          span {
            font-weight: 900;
            color: #18AC00;
          }
        }

        &_buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }

        &_cancelButton {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #18AC00;
          border: 2px solid #18AC00;
          border-radius: 57px;
          padding: 10px 45px;
          margin-right: 35px;
        }

        &_submitButton {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #FFFFFF;
          background: #18AC00;
          border-radius: 57px;
          border: none;
          padding: 10px 40px;

        }
      }
    }
  }
}