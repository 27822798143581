@import "../../../../app/styles/globalStyles";



.PastLesson {
    min-width: 250px;
    height: 260px;
    padding: 20px;
    background: #21C5EF;
    border-radius: 22px;
    position: relative;

    .bgImg {
        position: absolute;
        top: 5px;
        right: 0;
        width: 100px;
        height: 100px;
        opacity: 0.3;

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;

            path {
                stroke: #F5FDFF;
            }
        }
    }
    .row {
        width: 100%;
        margin-bottom: 15px;

        @include flex(flex, row, nowrap, space-between, center, unset);

        .lessonType {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #2ECC71;

            @include flex(flex, row, nowrap, center, center, unset);

            span {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 45px;
                color: #FFFFFF;
            }
        }
        .editIcon {
            width: 32px;
            height: 32px;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            position: relative;
            z-index: 2;
            margin-bottom: 28px;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .user {
            @include flex(flex, row, nowrap, flex-start, center, unset);

            .avatar {
                width: 25px;
                height: 25px;
                margin-right: 6px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .name {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                margin: 0;
            }
        }
        .linkIcon {
            width: 30px;
            height: 16px;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    span {
        display: block;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #EBEBEB;
        margin-bottom: 10px;
    }
}






@media screen and (max-width: 500px) {
    .PastLesson {
        min-width: 250px*0.8;
        height: 260px*0.8;
        padding: 20px*0.8;
        border-radius: 22px*0.8;
    
        .bgImg {
            top: 5px*0.8;
            width: 100px*0.8;
            height: 100px*0.8;
        }
        .row {
            margin-bottom: 15px*0.8;
    
            .lessonType {
                width: 60px*0.8;
                height: 60px*0.8;

                span {
                    font-size: 36px*0.8;
                    line-height: 45px*0.8;
                }
            }
            .editIcon {
                width: 32px*0.8;
                height: 32px*0.8;
                margin-bottom: 28px*0.8;
            }
            .user {
                .avatar {
                    width: 25px*0.8;
                    height: 25px*0.8;
                    margin-right: 6px*0.8;
                }
                .name {
                    font-size: 14px*0.8;
                    line-height: 17px*0.8;
                }
            }
            .linkIcon {
                width: 30px*0.8;
                height: 16px*0.8;
            }
        }
        .title {
            font-size: 24px*0.8;
            line-height: 30px*0.8;
            margin-bottom: 10px*0.8;
        }
        span {
            font-size: 16px*0.8;
            line-height: 19px*0.8;
            margin-bottom: 10px*0.8;
        }
    }
}