

.confirm-phone-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #2ECC71;
  margin-bottom: 29px;
  text-align: center;

}

.confirm-phone-form {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  padding: 0 18px;

  &::-webkit-scrollbar {
    width: 3px; /* ширина для вертикального скролла */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 48px;
  }

  label {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 11px;
  }

  &__input {
    border: 1px solid #5A5A5A;
    border-radius: 6px;
    width: 400px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #5A5A5A;
    padding: 20px;
    margin-bottom: 20px;

    &_last {
      margin-bottom: 10px;
    }
  }


  &__button-submit {
    background: #2ECC71;
    border-radius: 91px;
    border: none;
    padding: 15px;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 43px;

    &_disabled {
      background: gray;
      cursor: not-allowed;
    }
  }
}





.confirm-phone-footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 39px;

  &-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #616161;
  }

  &-btn {
    background: transparent;
    border: none;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-decoration-line: underline;
      text-decoration-color: #20C4F3;

      background: linear-gradient(180deg, #52E8A0 0%, #20C4F3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.confirm-phone-footer-bottom2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
  }
}


@media screen and (max-width: 500px){
  .confirm-phone-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2ECC71;
    margin-bottom: 20px;
    text-align: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

  }

  .confirm-phone-form {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    padding: 0 10px;

    &::-webkit-scrollbar {
      width: 3px; /* ширина для вертикального скролла */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 48px;
    }

    label {
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 11px;
    }

    &__input {
      border: 1px solid #5A5A5A;
      border-radius: 6px;
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #5A5A5A;
      padding: 10px;
      margin-bottom: 15px;

      &_last {
        margin-bottom: 10px;
      }
    }


    &__button-submit {
      display: flex;
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
      background: #2ECC71;
      border-radius: 91px;
      border: none;
      padding: 15px;
      width: 300px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      margin-top: 30px;
      margin-bottom: 30px;

      &_disabled {
        background: gray;
        cursor: not-allowed;
      }
    }
  }





  .confirm-phone-footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #616161;
    }

    &-btn {
      background: transparent;
      border: none;

      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-decoration-line: underline;
        text-decoration-color: #20C4F3;

        background: linear-gradient(180deg, #52E8A0 0%, #20C4F3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .confirm-phone-footer-bottom2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      text-align: center;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #000000;
    }
  }
}