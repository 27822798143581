@import "../../../app/styles/globalStyles";



.logo {
    width: 64px;
    height: 39px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}



@media screen and (max-width: 500px) {
    .logo {
        width: 64px*0.7;
        height: 39px*0.7;
    }
}