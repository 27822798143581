@import "../../../app/styles/globalStyles";

.student__wrapper {
  width: 100%;
}

.student__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #01682D;
  border-radius: 18px 18px 0px 0px;
  padding: 15px 43px;

  &-info {
    display: flex;
    align-items: center;
    gap: 23px;

    &-arrow {
      cursor: pointer;
    }

    img {
      width: 50px;
      height: 50px;
    }

    &-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
    }

    &-visit {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;

      &_bolt {
        font-weight: 600;
      }
    }
  }

  &-button {
    min-width: 192px;
    border: 2px solid #FFFFFF;
    border-radius: 57px;
    background: transparent;
    padding: 10px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;

    &_white {
      color: #000000;
      background: #FFFFFF;
    }
  }
}

.student__content {
  background: #2ECC71;
  border-radius: 0px 0px 8px 8px;
  padding: 50px 43px 125px 43px;

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    &-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #FFFFFF;

      &_margin {
        margin-bottom: 73px;
      }
    }
  }

  &-form {
    margin-bottom: 85px;
    .form {
      width: 100%;
      gap: 50px;

      @include flex(flex, column, nowrap, flex-start, center, unset);

      .inputs {
        width: 100%;

        @include flex(flex, row, nowrap, space-between, flex-start, unset);

        .left,
        .right {
          width: 620px;
          gap: 20px;

          @include flex(flex, column, nowrap, flex-start, flex-start, unset);

          .inputWrapper {
            width: 100%;
            gap: 11px;

            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            input {
              width: 100%;
              height: 59px;
              border: 1px solid #FFFFFF;
              border-radius: 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #FFFFFF;
              padding-left: 20px;
              background: transparent;

              &:disabled {
                opacity: 0.5;
              }
            }
            .label {
              font-weight: 700;
              font-size: 18px;
              line-height: 22px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 500px){
  .student__wrapper {
    width: 100%;
  }

  .student__head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #01682D;
    border-radius: 18px 18px 0px 0px;
    padding: 10px;

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      &-arrow {
        cursor: pointer;

        svg {
          width: 30px;
        }
      }

      img {
        width: 40px;
        height: 40px;
      }

      &-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
      }

      &-visit {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;

        &_bolt {
          font-weight: 600;
        }
      }
    }

    &-button {
      min-width: 192px;
      border: 2px solid #FFFFFF;
      border-radius: 57px;
      background: transparent;
      padding: 10px;

      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;

      &_white {
        color: #000000;
        background: #FFFFFF;
      }
    }
  }

  .student__content {
    background: #2ECC71;
    border-radius: 0px 0px 8px 8px;
    padding: 10px;

    &-head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 10px;

        &_margin {
          margin-bottom: 23px;
        }
      }
    }

    &-form {
      margin-bottom: 50px;
      .form {
        width: 100%;
        gap: 50px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        .inputs {
          width: 100%;

          @include flex(flex, column, nowrap, space-between, flex-start, unset);

          .left,
          .right {
            width: 100%;
            gap: 15px;

            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            .inputWrapper {
              width: 100%;
              gap: 8px;

              @include flex(flex, column, nowrap, flex-start, flex-start, unset);

              input {
                width: 100%;
                height: 59px;
                border: 1px solid #FFFFFF;
                border-radius: 6px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                padding-left: 20px;
                background: transparent;

                &:disabled {
                  opacity: 0.5;
                }
              }
              .label {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}