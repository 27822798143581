@import "../../../app/styles/globalStyles";



.TestQuestions {
    width: 100%;
    height: calc(100vh - 64px);

    @include flex(flex, column, nowrap, flex-start, center, unset);
}
.TestQuestions_header {
    width: 100%;
    padding: 17px 240px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include flex(flex, row, nowrap, space-between, center, unset);

    .logo {
        width: 170px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #2ECC71;
        white-space: nowrap;
    }
    .questionsLine {
        width: 1053px;
        height: 10px;
        background: linear-gradient(0deg, #EAEAEA, #EAEAEA), #ACACAC;
        border-radius: 25px;
        overflow: hidden;

        .innerLine {
            height: 100%;
            background: linear-gradient(0deg, #2ECC71, #2ECC71), #ACACAC;
            border-radius: 25px;
            position: relative;
            z-index: 2;
            transition: 0.2s;
        }
    }
    .timeWrapper {
        width: 102px;
        gap: 15px;

        @include flex(flex, row, nowrap, space-between, center, unset);
    }
}
.TestContent {
    width: 100%;
    padding: 91px 240px;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .left,
    .right {
        height: 510px;
        background: #FFFFFF;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        &.mobileRight {
            display: none;
        }

        .top {
            @include flex(flex, row, nowrap, center, center, unset);

            padding: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            border-bottom: 1px solid #BBBBBB;
        }
        .content {
            height: 420px;

            .row {
                width: 100%;
                padding: 9px 15px;
                gap: 15px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                .icon {
                    width: 21px;
                    height: 16px;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                        path {
                            fill: #000000;
                        }
                    }
                }
                .questionIcon {
                    width: 20px;
                    height: 18px;
                    background-color: #FFFFFF;
                    border-radius: 3px;

                    &.true {
                        background: #18AC00;
                    }
                    &.false {
                        background: #ED2F05;
                    }
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                }

                &.selected {
                    background-color: #2ECC71;
                    
                    span {
                        color: #FFFFFF;
                    }
                    .icon {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
        .bottom {
            @include flex(flex, row, nowrap, center, center, unset);

            padding: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            border-top: 1px solid #BBBBBB;

            span {
                color: #2ECC71;
            }
        }
    }

    .left {
        width: 194px;
    }
    .right {
        width: 275px;

        &.mobileRight {
            display: none;
        }
    }

    .center {
        width: 931px;

        .top {
            background: #FFFFFF;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 10px 36px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #434343;
            margin-bottom: 15px;
        }
        .questionContent {
            padding: 20px 36px;
            background: #FFFFFF;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            .text {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #000000;
                margin-bottom: 25px;
            }
        }
        .btns {
            width: 100%;
            margin-top: 27px;
            gap: 48px;

            @include flex(flex, row, nowrap, flex-end, center, unset);

            button {
                padding: 12px 27px;
                background: #2ECC71;
                border-radius: 60px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                border: none;
                outline: none;
            }
        }
    }
}










@media screen and (max-width: 500px) {
    .TestQuestions {
        height: 100%;
    }
    .TestQuestions_header {
        padding: 10px;
        gap: 15px;

        .logo {
            width: 100px;
            font-size: 24px*0.6;
            line-height: 30px*0.6;
            text-align: center;
            white-space: normal;

            @include flex(flex, row, nowrap, center, center, unset);
        }
        .questionsLine {
            
        }
        .timeWrapper {
            gap: 10px;
        }
    }
    .TestContent {
        width: 100%;
        padding: 42px 10px;
        gap: 30px;
        margin-bottom: 42px;

        @include flex(flex, column, nowrap, flex-start, center, unset);
    
        .left,
        .right {
            width: 100%;
            height: auto;

            .content {
                height: auto;
    
                .row {
                    
                }
            }
        }
    
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
            display: none;

            &.mobileRight {
                display: flex;
                flex-direction: column;
            }
        }
    
        .center {
            width: 100%;
    
            .top {
                padding: 20px;
                margin-bottom: 15px;
            }
            .questionContent {
                padding: 20px;
    
                .text {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .btns {
                gap: 20px;
    
                @include flex(flex, row, nowrap, center, center, unset);
    
                button {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}