@import "../../../app/styles/globalStyles";



/* .ql-toolbar {
    background-color: #fff;
} */



.rdw-editor-main {
    background-color: #fff;
    height: 200px;
}
.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: none;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: flex-start;
    background: black;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 0;
    -webkit-user-select: none;
    user-select: none;
}
.rdw-editor-main {
    border-radius: 0px 0px 20px 20px;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 5px;
        background-color: #666;
    }
}

@media screen and (max-width: 500px) {
    .rdw-editor-toolbar {
        &>div {
            gap: 10px 0;
        }
    }
}