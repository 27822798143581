@import "../../../app/styles/globalStyles";



.ProfilePage {
    width: 100%;

    .form {
        width: 100%;
        gap: 50px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        .inputs {
            width: 100%;

            @include flex(flex, row, nowrap, space-between, flex-start, unset);

            .left,
            .right {
                width: 620px;
                gap: 20px;

                @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                .inputWrapper {
                    width: 100%;
                    gap: 11px;

                    @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                    input {
                        width: 100%;
                        height: 59px;
                        border: 1px solid #5A5A5A;
                        border-radius: 6px;
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: #5A5A5A;
                        padding-left: 20px;

                        &:disabled {
                            opacity: 0.5;
                        }
                    }
                    .label {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: #2ECC71;
                    }
                }
            }
        }
        .button {
            background-color: #2ECC71;
            padding: 14px 138px;
            font-family: Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}








@media screen and (max-width: 500px) {
    .ProfilePage {
        .form {
            .inputs {
                width: 100%;
    
                @include flex(flex, column, nowrap, flex-start, center, unset);
    
                .left,
                .right {
                    width: 100%;
                    gap: 20px;
                    margin-bottom: 20px;
    
                    @include flex(flex, column, nowrap, flex-start, flex-start, unset);
    
                    .inputWrapper {
                        width: 100%;
                        gap: 3px;
                    }
                }
            }
            .button {
                padding: 14px 42px;
                font-size: 24px*0.8;
                line-height: 29px*0.8;
                border-radius: 30px;
            }
        }
    }
}