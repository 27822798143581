

.collapseButton {
  border: none;

  &_content {
    display: flex;
    align-items: center;


    img {
      margin-left: 10px;
    }
  }
}