@import "../../app/styles/globalStyles";

.faq_wrapper {

  .faq_breadcrumbs {

    padding: 50px 240px 20px 240px;
  }

  .faq_bg {
    background-image: url("../../shared/ui/img/faqPageBg.png");
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 340px;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    align-items: center;
  }

  .faq_content {
    padding: 100px 240px 50px 240px;

    &_accordion {

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        color: #2ECC71;
        margin-bottom: 37px;

      }

      .FaqPageAccordion {
        margin-bottom: 100px;

        .ant-collapse-header {
          padding: 13px 50px 13px 0;
          border-bottom: 1px solid #000000;
          margin-bottom: 37px;
          border-radius: 0;

          &-text {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.02em;
            color: #000000;
          }

          .ant-collapse-arrow {

            line {
              stroke: black;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .faq_wrapper {

    .faq_breadcrumbs {

      padding: 20px;
    }

    .faq_bg {
      background-image: url("../../shared/ui/img/faqPageBg.png");
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 250px;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      color: #FFFFFF;
      padding: 20px;
    }

    .faq_content {
      padding: 20px;

      &_accordion {

        &_title {
          font-weight: 700;
          font-size: 24px;
          line-height: 45px;
          color: #2ECC71;
          margin-bottom: 27px;

        }

        .FaqPageAccordion {
          margin-bottom: 60px;

          .ant-collapse-header {
            padding: 13px 30px 13px 0;
            border-bottom: 1px solid #000000;
            margin-bottom: 20px;
            border-radius: 0;
            align-items: center;

            &-text {
              font-weight: 500;
              font-size: 20px;
              line-height: 29px;
              letter-spacing: 0.02em;
              color: #000000;
            }

            .ant-collapse-expand-icon {

              svg {
                fill: black;
              }
            }
          }
        }
      }
    }
  }
}
