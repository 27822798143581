@import "../../../../../app/styles/globalStyles";


.student-row {
  width: 100%;
  background-color: #007D35;
  border-radius: 0;


  &:first-child {
    td {
      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  &:nth-last-child(2) {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }



  td {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 15px 0;
    /*  */

    &:first-child {
      padding-left: 37px;
      //border-top-left-radius: 8px*$vw;

    }

    &:last-child {
      padding-right: 45px;
      //border-top-right-radius: 8px*$vw;
      //border-bottom-right-radius: 8px*$vw;
    }
  }

  &:nth-child(4n + 1) {
    background-color: #00AD49;
  }

  .arrow_w {
    width: 100%;

    @include flex(flex, row, nowrap, center, center, unset);

    .arrow {
      width: 42px;
      height: 24px;
      margin: 0;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}


@media screen and (max-width: 500px){
  .student-row {
    width: 100%;
    background-color: #007D35;
    border-radius: 0;



    &:first-child {
      td {
        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    &:nth-last-child(2) {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    &__img {
      img {
        width: 30px;
      }
    }

    &__arrow {
      display: none;
    }

    td {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #FFFFFF;
      padding: 8px;
      /*  */

      &:first-child {
        padding-left: 10px;
        //border-top-left-radius: 8px*$vw;

      }

      &:last-child {
        padding-right: 10px;
        //border-top-right-radius: 8px*$vw;
        //border-bottom-right-radius: 8px*$vw;
      }
    }

    &:nth-child(4n + 1) {
      background-color: #00AD49;
    }

    .arrow_w {
      width: 100%;

      @include flex(flex, row, nowrap, center, center, unset);

      .arrow {
        width: 20px;
        height: 24px;
        margin: 0;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}