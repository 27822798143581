@import "./normalize";

@import "./fonts/Gilroy/stylesheet.css";

html * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Gilroy;
}
body {
    height: 100vh;
    overflow-x: hidden;
}
a {
    text-decoration: none;
    color: #282828;

}
button {
    cursor: pointer;
}