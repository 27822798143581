

.user-cabinet {
  padding: 50px 240px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;

    &-user {
      display: flex;

      &-desc {
        margin-left: 13px;

        &-name {
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          color: #2ECC71;
          margin-bottom: 8px;
        }

        &-id {
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #000000;

          span {
            color: #C4C3C3;
          }
        }
      }
    }

    &-notification {

      &-button {
        border-radius: 50%;
        background: #2ECC71;
        border: none;
        width: 51px;
        height: 51px;
        position: relative;

        img {
          position: absolute;
          left: 50%;
          margin-left: -13px;
          top: 50%;
          margin-top: -15px;
        }
      }
    }
  }
}


@media screen and (max-width: 500px){
  .user-cabinet {
    padding: 20px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 38px;

      &-user {
        display: flex;

        &-desc {
          margin-left: 13px;

          &-name {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #2ECC71;
            margin-bottom: 8px;
          }

          &-id {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #000000;

            span {
              color: #C4C3C3;
            }
          }
        }
      }

      &-notification {

        &-button {
          border-radius: 50%;
          background: #2ECC71;
          border: none;
          width: 51px;
          height: 51px;
          position: relative;

          img {
            position: absolute;
            left: 50%;
            margin-left: -13px;
            top: 50%;
            margin-top: -15px;
          }
        }
      }
    }
  }
}