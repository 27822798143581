@import "../../../app/styles/globalStyles";



.OnlineLesson {
    width: 100%;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .OnlineLessonSidebar_w {
        width: 267px;

        .OnlineLessonSidebar {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            .top {
                padding: 15px 47px;
                border-bottom: 1px solid #979797;

                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: #000000;
                white-space: nowrap;

                @include flex(flex, column, nowrap, center, center, unset);
            }
            .content {
                width: 100%;
                height: 400px;
                max-height: 400px;
                padding: 15px 0;
                overflow-y: auto;

                @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                button {
                    width: 100%;
                    height: 40px;
                    padding: 8px 22px;
                    background: transparent;
                    border: none;
                    outline: none;
                    text-align: left;

                    span {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #000000;
                    }
                    &.selected {
                        background: #2ECC71;

                        span {
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .bottom {
                width: 100%;
                height: 45px;
                border-top: 1px solid #979797;
            }
        }
    }
    .OnlineLessonContent_w {
        width: 1117px;

        .OnlineLessonHeader {
            width: 100%;
            padding-bottom: 23px;

            @include flex(flex, row, nowrap, space-between, center, unset);

            .title {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 45px;
                color: #000000;
            }
            .createLesson {
                border: none;
                outline: none;
                background-color: transparent;
                gap: 13px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }
                .icon {
                    width: 18px;
                    height: 18px;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .OnlineLessonContent {
            width: 100%;
            padding-right: 2px;

            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            .pastLessons {
                width: 100%;
                min-height: 280px;
                overflow-x: auto;
                gap: 38px;

                @include flex(flex, row, nowrap, flex-start, flex-start, unset);

                &::-webkit-scrollbar {
                    height: 6px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.25);
                    cursor: pointer;
                }
            }
            .futureLessons {
                width: 100%;
                margin-top: 50px;

                @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                .title {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px*0.7;
                    line-height: 45px*0.7;
                    color: #000000;
                    margin-bottom: 40px;
                }
                .futureLessons_content {
                    width: 100%;
                    padding: 5px;
                    gap: 30px;
                    max-height: 700px;
                    overflow-y: auto;

                    @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                    &::-webkit-scrollbar {
                        width: 6px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.25);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}





@media screen and (max-width: 500px) {
    .OnlineLesson {
        width: 100%;
    
        @include flex(flex, column, nowrap, flex-start, center, unset);
    
        .OnlineLessonSidebar_w {
            width: 100%;
    
            .OnlineLessonSidebar {
                width: 100%;
    
                .top {
                    width: 100%;
                    font-size: 24px*0.9;
                    line-height: 30px*0.9;
                }
                .content {
                    width: 100%;
                    height: auto;
                    max-height: 200px;
                }
            }
        }
        .OnlineLessonContent_w {
            width: 100%;
            margin-top: 40px;
    
            .OnlineLessonHeader {
                padding-bottom: 23px;
    
                .title {
                    font-size: 36px*0.6;
                    line-height: 45px*0.6;
                }
            }
    
            .OnlineLessonContent {
                width: 100%;

                .pastLessons {
                    width: 100%;
                    min-height: 220px;
                    gap: 20px;
                }
                .futureLessons {
                    width: 100%;
                    margin-top: 40px;
    
                    .title {
                        font-size: 36px*0.7;
                        line-height: 45px*0.7;
                        margin-bottom: 20px;
                    }
                    .futureLessons_content {
                        gap: 20px;
                        max-height: 700px;
                    }
                }
            }
        }
    }
}