@import "../../../../app/styles/globalStyles";



.saveIcon {
    width: 13px;
    height: 17px;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}