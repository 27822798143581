@import "../../../app/styles/globalStyles";



.ExamQuestions {
    width: 100%;
    height: 100%;

    @include flex(flex, column, nowrap, flex-start, center, unset);
}
.ExamQuestions_header {
    width: 100%;
    padding: 17px 240px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include flex(flex, row, nowrap, space-between, center, unset);

    .logo {
        width: 170px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #2ECC71;
        white-space: nowrap;
    }
    .questionsLine {
        width: 1053px;
        height: 10px;
        background: linear-gradient(0deg, #EAEAEA, #EAEAEA), #ACACAC;
        border-radius: 25px;
        overflow: hidden;

        .innerLine {
            height: 100%;
            background: linear-gradient(0deg, #2ECC71, #2ECC71), #ACACAC;
            border-radius: 25px;
            position: relative;
            z-index: 2;
            transition: 0.2s;
        }
    }
    .timeWrapper {
        width: 102px;
        gap: 15px;

        @include flex(flex, row, nowrap, space-between, center, unset);
    }
}
.ExamContent {
    width: 100%;
    padding: 91px 240px;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .left,
    .right {
        height: 510px;
        background: #FFFFFF;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        &.mobileRight {
            display: none;
        }

        .top {
            @include flex(flex, row, nowrap, center, center, unset);

            padding: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            border-bottom: 1px solid #BBBBBB;
        }
        .content {
            height: 420px;

            .row {
                width: 100%;
                padding: 5px 15px;
                gap: 15px;
                border-radius: 36px;

                @include flex(flex, row, nowrap, flex-start, center, unset);

                .icon {
                    width: 21px;
                    height: 16px;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                        path {
                            fill: #000000;
                        }
                    }
                }
                .questionIcon {
                    width: 20px;
                    height: 18px;
                    background-color: #FFFFFF;
                    border-radius: 3px;

                    &.true {
                        background: #18AC00;
                        border: none !important;
                    }
                    &.false {
                        background: #ED2F05;
                        border: none !important;
                    }
                    .arrow {
                        display: none;
                    }
                    &.done {
                        @include flex(flex, row, nowrap, center, center, unset);

                        .arrow {
                            display: block;
                            width: 13px;
                            height: 14px;
                            margin-bottom: 3px;

                            svg {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000;
                }

                &.selected {
                    background-color: #fff;
                    
                    span {
                        color: #000;
                    }
                    .icon {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                    .questionIcon {
                        border: 1px solid #000000;
                    }
                }
            }
        }
        .menuContent {
            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            gap: 10px;
        }
        .bottom {
            @include flex(flex, row, nowrap, center, center, unset);

            padding: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            border-top: 1px solid #BBBBBB;

            span {
                color: #2ECC71;
            }
        }
    }

    .left {
        width: 194px;
    }
    .right {
        width: 451px;

        &.mobileRight {
            display: none;
        }
    }

    .center {
        width: 745px;

        .top {
            background: #FFFFFF;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 10px 36px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #434343;
            margin-bottom: 15px;
        }
        .questionContentWrapper {
            height: 701px;
            padding: 20px 36px;
            padding-right: 10px;
            background: #FFFFFF;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
        }
        .questionContent {
            width: 100%;
            height: 100%;
            padding: 0;
            padding-right: 36px;
            padding-left: 5px;
            border-radius: 0;
            box-shadow: none;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 3px;
                background-color: transparent;
                border-radius: 32px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #000;
            }

            h3 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: #000000;
                margin-bottom: 30px;
            }

            .warning {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: #000000;
                margin-bottom: 53px;

                span {
                    padding: 5px 20px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 25px;
                    color: #FFFFFF;
                    background: linear-gradient(180deg, #2ECC71 0%, #20C4F3 100%);
                    border-radius: 3px;
                }
            }

            .abzats {
                margin-bottom: 30px;

                .title {
                    width: fit-content;
                    padding: 5px 22px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                    background: #2ECC71;
                    border-radius: 6px;
                    margin-bottom: 23px;
                }
                p {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 187.5%;
                    color: #000000;
                    /* margin-top: 23px; */
                }
            }
        }
        .btns {
            width: 100%;
            margin-top: 27px;
            gap: 48px;

            @include flex(flex, row, nowrap, flex-end, center, unset);

            button {
                padding: 12px 27px;
                background: #2ECC71;
                border-radius: 60px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                border: none;
                outline: none;
            }
        }
    }

    .rightQuestionsW {
        width: 451px;
        height: 701px;
    }
    .rightQuestionsContent {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 10px;

        @include flex(flex, column, nowrap, flex-start, center, unset);

        gap: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        .question {
            width: 441px;
            padding: 30px 25px;
            background: #FFFFFF;
            box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            h4 {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                text-align: center;
                color: #000000;
                margin-bottom: 37px;
            }
        }
    }



    .ExamPageAccordionMenu {
        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        .ant-collapse-item-active {
            background-color: #21C5EF;

            .MenuTitle {
                .icon svg path {
                    fill: #fff;
                }
            }
        }
        .ant-collapse-item {
            width: 100%;
            padding: 5px 0;
        }
        .ant-collapse-header {
            position: relative;
        }
        .ant-collapse-expand-icon {
            position: absolute;
            top: 4px;
            right: 10px;

            .arrow {
                width: 11px;
                height: 10px;
                transform: rotate(180deg);

                @include flex(flex, row, nowrap, center, center, unset);

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
    
                    path {
                        stroke: #000000;
                    }
                }
            }
        }
        .ant-collapse-header {
            padding: 0;
        }
    }
}
.ExamContent .left .content .ant-collapse-item-active {
    .row {
        &.selected {
            span {
                color: #000;
            }
        }
        span {
            color: #fff;
        }
    }
    .ant-collapse-expand-icon {
        .arrow {
            transform: rotate(0);

            svg {
                path {
                    stroke: #fff;
                }
            }
        }
    }
}










@media screen and (max-width: 500px) {
    .ExamQuestions {
        
    }
    .ExamQuestions_header {
        padding: 10px;
        gap: 15px;

        .logo {
            width: 100px;
            font-size: 24px*0.6;
            line-height: 30px*0.6;
            text-align: center;
            white-space: normal;

            @include flex(flex, row, nowrap, center, center, unset);
        }
        .questionsLine {
            
        }
        .timeWrapper {
            gap: 10px;
        }
    }
    .ExamContent {
        width: 100%;
        padding: 42px 10px;
        gap: 30px;
        margin-bottom: 42px;

        @include flex(flex, column, nowrap, flex-start, center, unset);
    
        .left,
        .right {
            width: 100%;
            height: auto;

            .content {
                height: auto;
    
                .row {
                    
                }
            }
        }
    
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
            display: none;

            &.mobileRight {
                display: flex;
                flex-direction: column;
            }
        }
    
        .center {
            width: 100%;
    
            .top {
                padding: 20px;
                margin-bottom: 15px;
            }
            .questionContentWrapper {
                height: 85vh;
            }
            .questionContent {
                padding: 20px;
    
                .text {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .btns {
                gap: 20px;
    
                @include flex(flex, row, nowrap, center, center, unset);
    
                button {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        .rightQuestionsW {
            width: 100%;
            height: 85vh;
        }
        .rightQuestionsContent {
            width: 100%;
            height: 100%;
            padding-bottom: 10px;

            .question {
                width: 95%;
                padding: 30px*0.5 25px*0.5;

                h4 {
                    font-size: 20px;
                    line-height: 25px;
                    margin-bottom: 37px;
                }
            }
        }
    }
}