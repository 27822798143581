@import "../../../../app/styles/globalStyles";



.forumListHeader {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #535353;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .left,
    .right {
        @include flex(flex, row, nowrap, flex-start, center, unset);

        .btn {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #535353;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0 13px;
            transition: 0.3s;

            &.selected {
                font-weight: 700;
                color: #0D0D0D;
                position: relative;

                &::after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    bottom: -11.5px;
                    background-color: #2ECC71;
                    border-radius: 52px;
                }
            }
        }
    }
}





@media screen and (max-width: 500px) {
    .forumListHeader {
        width: 91%;
        padding: 3px 0;
        flex-wrap: wrap;
        gap: 20px;
        margin-left: 40px;
        /* position: relative; */

        

        .left,
        .right {
            align-items: flex-end;
            .btn {
                font-size: 18px*0.7;
                line-height: 22px*0.7;
                padding: 0 13px*0.7;

                &.selected {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 3px;
                        left: 0;
                        bottom: -5px;
                        border-radius: 52px;
                    }
                }
            }
        }
    }
}