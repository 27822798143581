@import "../../app/styles/globalStyles";

.wrapper {


  .content1 {
    @include flex(flex, row, nowrap, space-between, center, unset);
    height: 940px;
    padding: 0 240px 0 240px;
    background: linear-gradient(180deg, #2ECC71 0%, #24C6CC 79.62%, #20C4F3 100%);

    .content_left {
      width: 994px;
      padding: 178px 0 152px 0;
      @include flex(flex, column, nowrap, space-between, flex-start, unset);

      .title {
        font-weight: 700;
        font-size: 64px;
        line-height: 79px;
        color: #FFFFFF;
        margin-bottom: 60px;
      }

      .description {
        font-weight: 500;
        font-size: 32px;
        line-height: 54px;
        color: #F3F3F3;
        margin-bottom: 63px;
      }

      .content1_buttons {

        &__button {
          min-width: 350px;
          border: 3px solid #FFFFFF;
          border-radius: 60px;
          padding: 16px;
          background: transparent;

          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #FFFFFF;
          transition: all 0.3s;

          &:hover {
            background: #2ECC71;
          }

          &_white {
            background: #FFFFFF;
            color: #2ECC71;
            margin-right: 44px;

            &:hover {
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .content2 {
    height: 1000px;

    .content2_center {
      @include flex(flex, column, nowrap, space-between, center, unset);

      .content2_card {
        width: 433px;
        margin-top: 90px;
        @include flex(flex, column, nowrap, space-between, flex-start, unset);

        .content2_card_title {
          margin-top: 22px;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
        }

        .content2_card_desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #777777;
        }
      }

      .center {
        @include flex(flex, row, nowrap, space-between, flex-start, unset);
        align-items: center;

        .content2_card_desc_center {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .content3 {
    height: 664px;
    background: linear-gradient(180deg, #2ECC77 0%, #21C5EE 100%);

    //background-image: url("../../../shared/ui/img/MainPage/content3BG.png");

    &_title {
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      padding-top: 37px;
      margin-bottom: 61px;
    }

    &_card_container {
      width: 1010px;
      margin: 0 auto;
      @include flex(flex, row, nowrap, space-between, flex-start, unset);

      .content3_card {
        width: 400px;
        height: 480px;
        background: #FFFFFF;
        border-radius: 19px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          &_number {
            font-weight: 700;
            font-size: 64px;
            line-height: 79px;
            color: #2ECC71;
            margin-right: 20px;

          }

          &_desc {
            @include flex(flex, column, nowrap, space-between, flex-start, unset);

            &_up {
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              color: #2ECC71;
            }

            &_down {
              font-weight: 700;
              font-size: 24px;
              line-height: 30px;
              color: #20C4F3;
            }
          }

        }

        &_desc {
          border-top: 1px solid #CDCDCD;
          margin: 0 27px;
          padding-top: 12px;
          @include flex(flex, column, nowrap, space-between, center, unset);

          &_title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #000000;
            margin-bottom: 20px;

          }

          &_ul {
            width: 290px;
            li {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #777777;
              margin-bottom: 20px;
            }
          }
        }

        &_button {
          background: #2ECC71;
          border-radius: 60px;
          width: 350px;
          height: 63px;
          border: 2px solid #2ECC71;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #FFFFFF;
          margin-top: 17px;
          transition: all 0.3s;

          &:hover {
            background: #FFFFFF;
            color: #2ECC71;
          }
        }
      }
    }
  }

  .content4 {
    padding: 100px 240px;
    @include flex(flex, row, nowrap, space-between, center, unset);

    &_content {
      @include flex(flex, column, nowrap, space-between, end, unset);
      margin-left: 90px;

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: 0.195em;
        color: #2ECC71;
        margin-bottom: 40px;
        text-align: right;
      }

      &_desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        color: #5E5E5E;
        margin-bottom: 50px;
      }

      &_link {
        display: flex;

        &_desc {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.195em;
          background: linear-gradient(180deg, #2ECC71 0%, #20C4F3 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-right: 20px;
        }
      }
    }
  }

  .content5 {
    padding: 36px 0 38px 240px;
    background:  #2ECC71;
    @include flex(flex, row, nowrap, space-between, center, unset);

    &_content {
      width: 632px;
      @include flex(flex, column, nowrap, space-between, start, unset);

      &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        letter-spacing: 0.195em;
        color: #FFFFFF;
        margin-bottom: 40px;
      }

      &_desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 41px;

      }

      &_link {
        display: flex;

        &_desc {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.195em;
          color: #000000;
          margin-right: 20px;
        }
      }
    }
    &_slider {
      width: 962.47px;
      overflow: hidden;
      padding: 35px 0;

      .mainPageSlider {
        width: 100%;
        margin-left: 156px;

        .slick-dots::before {
          background-color: #FFFFFF;
        }

        .slick-dots::after {
          color: #FFFFFF;
        }

        .slick-dots li button {
          color: #FFFFFF;
        }

        .controls {

          .button {
            background-color: #FFFFFF;

            svg {
              fill: #2ECC77;
            }
          }
        }
      }
    }
  }

  .content6 {
    padding: 100px 240px;

    .FaqAccordion {

      .ant-collapse-header {
        padding: 13px 10px;
        border-bottom: 1px solid #B6B6B6;
        margin-bottom: 37px;

        &-text {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.02em;
          color: #414141;
        }
      }
    }

    &_title {
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #000000;
      margin-bottom: 60px;

      span {
        color: #2ECC71;
      }
    }
  }
}



@media screen and (max-width: 500px) {
  .wrapper {


    .content1 {
      @include flex(flex, column, nowrap, space-between, center, unset);
      height: auto;
      padding: 20px;
      background: linear-gradient(180deg, #2ECC71 0%, #24C6CC 79.62%, #20C4F3 100%);


      .content_left {
        width: 100%;
        padding: 20px 0 20px 0;
        @include flex(flex, column, nowrap, space-between, center, unset);


        .title {
          width: 100%;
          font-weight: 700;
          font-size: 26px;
          line-height: 35px;
          color: #FFFFFF;
          margin-bottom: 40px;
        }

        .description {
          font-weight: 500;
          font-size: 22px;
          line-height: 30px;
          color: #F3F3F3;
          margin-bottom: 50px;
        }

        .content1_buttons {

          &__button {
            min-width: 300px;
            border: 3px solid #FFFFFF;
            border-radius: 60px;
            padding: 10px;
            background: transparent;

            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #FFFFFF;

            &_white {
              background: #FFFFFF;
              color: #2ECC71;
              margin-right: 0px;
              margin-bottom: 15px;
            }
          }
        }
      }

      .content_right {
        display: none;

      }

    }

    .content2 {
      height: auto;
      padding: 20px;

      .content2_center {
        @include flex(flex, column, nowrap, space-between, center, unset);

        .content2_card {
          width: 100%;
          @include flex(flex, column, nowrap, space-between, center, unset);

          .content2_card_title {
            margin-top: 22px;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
          }

          .content2_card_desc {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #777777;
            text-align: center;
          }
        }

        .center {
          @include flex(flex, column, nowrap, space-between, center, unset);


          .content2_card_desc_center {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 15px;
            text-align: center;
          }
        }
      }
    }

    .content3 {
      height: 100%;
      padding: 20px;
      background: linear-gradient(180deg, #2ECC77 0%, #21C5EE 100%);


      &_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        padding-top: 37px;
        margin-bottom: 50px;
      }

      &_card_container {
        width: 100%;
        margin: 0 auto;
        @include flex(flex, column, nowrap, space-between, flex-start, unset);

        .content3_card {
          width: 100%;
          height: 480px;
          background: #FFFFFF;
          border-radius: 19px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          margin-bottom: 20px;

          &_header {
            display: flex;
            align-items: center;
            justify-content: center;

            &_number {
              font-weight: 700;
              font-size: 64px;
              line-height: 79px;
              color: #2ECC71;
              margin-right: 20px;

            }

            &_desc {
              @include flex(flex, column, nowrap, space-between, flex-start, unset);

              &_up {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2ECC71;
              }

              &_down {
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: #20C4F3;
              }
            }

          }

          &_desc {
            border-top: 1px solid #CDCDCD;
            margin: 0 15px;
            padding-top: 12px;
            @include flex(flex, column, nowrap, space-between, center, unset);

            &_title {
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #000000;
              margin-bottom: 20px;

            }

            &_ul {
              width: 270px;
              list-style: inside;
              li {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #777777;
                margin-bottom: 20px;
              }
            }
          }

          &_button {
            background: #2ECC71;
            border-radius: 60px;
            width: 100%;
            height: 50px;
            border: none;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #FFFFFF;
            margin-top: 17px;
            width: 250px;
          }
        }
      }
    }

    .content4 {
      padding: 20px;
      @include flex(flex, column, nowrap, space-between, center, unset);

      img {
        width: 300px;
      }

      &_content {
        @include flex(flex, column, nowrap, space-between, center, unset);
        margin: 0 auto;

        &_title {
          font-weight: 700;
          font-size: 24px;
          line-height: 35px;
          letter-spacing: 0.195em;
          color: #2ECC71;
          margin-bottom: 30px;
          text-align: center;
        }

        &_desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #5E5E5E;
          margin-bottom: 30px;
        }

        &_link {
          display: flex;

          img {
            width: 20px;
          }

          &_desc {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.195em;
            background: linear-gradient(180deg, #2ECC71 0%, #20C4F3 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-right: 20px;
          }
        }
      }
    }

    .content5 {
      padding: 20px;
      background:  #2ECC71;
      @include flex(flex, column, nowrap, space-between, center, unset);

      &_content {
        width: 100%;
        @include flex(flex, column, nowrap, space-between, start, unset);

        &_title {
          font-weight: 700;
          font-size: 24px;
          line-height: 35px;
          text-align: center;
          letter-spacing: 0.195em;
          color: #FFFFFF;
          margin-bottom: 30px;
        }

        &_desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #FFFFFF;
          margin-bottom: 41px;

        }

        &_link {
          display: flex;

          &_desc {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.195em;
            color: #000000;
            margin-right: 20px;
          }
        }
      }
      &_slider {
        width: 100%;
        overflow: hidden;
        padding: 35px 0;

        .mainPageSlider {
          width: 100%;
          margin-left: 0;
          padding-bottom: 50px;
          .slick-dots::before {
            background-color: #FFFFFF;
          }

          .slick-dots::after {
            color: #FFFFFF;
          }

          .slick-dots li button {
            color: #FFFFFF;
          }

          .controls {

            .button {
              background-color: #FFFFFF;

              svg {
                fill: #2ECC77;
              }
            }
          }
        }
      }
    }

    .content6 {
      padding: 5px;
      margin-bottom: 60px;

      .FaqAccordion {

        .ant-collapse-header {
          padding: 13px 10px;
          border-bottom: 1px solid #B6B6B6;
          margin-bottom: 20px;
          align-items: center;

          &-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.02em;
            color: #414141;
          }
        }
      }

      &_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 44px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #000000;
        margin-bottom: 60px;

        span {
          color: #2ECC71;
        }
      }
    }
  }
}