@import "../../../../app/styles/globalStyles";



.PlaySound {
    position: relative;

    @include flex(flex, column, nowrap, center, center, unset);

    .btn {
        width: 90px;
        height: 90px;
        background: #20C4F3;
        border-radius: 50%;
        margin-bottom: 15px;
        cursor: pointer;
        position: relative;
        z-index: 2;
        
        @include flex(flex, row, nowrap, center, center, unset);

        img {
            width: 30.52px;
            height: 30.52px;
            margin-left: 3px;
            margin-top: 2px;
        }
    }
    span {
        font-family: 'Gilroy';
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
    .progress {
        position: absolute;
        top: -4px;

        .circle-background {
            stroke: transparent;
        }
        .circle-progress {
            stroke: #20C4F3;
        }
    }
}