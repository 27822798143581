@import "../../../../app/styles/globalStyles";



.Slider1 {
    .slick-slider {
        position: relative;
    }
    .slick-track {
        display: flex;
    }
    .slick-list {
        width: 100%;
        /* margin-right: 500px; */
        transform: translate(-44%);
    }
    .slick-slide {
        width: 376px;
        height: 455px;
        transition: 0.3s;
    }
    .slide {
        width: 326px !important;
        height: 455px;
        border-radius: 14px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .slick-current {
        scale: 1.1;
    }
    .slick-dots {
        width: 70px;
        list-style: none;
        position: absolute;
        left: 400px;
        bottom: -100px;

        @include flex(flex !important, row, nowrap, space-between, center, unset);

        &::before {
            content: '';
            position: absolute;
            right: 130%;
            width: 150px;
            height: 1px;
            background-color: #000;
        }
        &::after {
            content: '/';
            position: absolute;
            right: 40%;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.195em;
            color: #000000;
        }

        li {
            display: none;

            &.slick-active,
            &:last-child {
                display: block;
            }

            button {
                border: none;
                outline: none;
                background-color: transparent;
                
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                text-align: center;
                letter-spacing: 0.195em;
                color: #000000;
            }
        }
    }

    .controls {
        width: 120px;
        margin-top: 63px;

        @include flex(flex, row, nowrap, space-between, center, unset);

        .button {
            width: 50px;
            height: 50px;
            background-color: #2ECC71;
            border-radius: 50%;
            border: none;
            outline: none;

            @include flex(flex, row, nowrap, center, center, unset);

            &:first-child {
                svg {
                    margin-right: 3px;
                }
            }
            &:last-child {
                svg {
                    margin-left: 3px;
                }
            }

            svg {
                width: 45%;
                height: 45%;
                object-fit: contain;
            }
        }
    }
}





@media screen and (max-width: 500px) {
    .Slider1 {
        padding: 20px 0;
        padding-bottom: 100px;
        overflow: hidden;

        .slick-list {
            width: 100%;
            transform: none;
        }
        .slick-slide {
            width: 376px*0.45;
            height: 455px*0.45;
            transition: 0.3s;
        }
        .slide {
            width: 326px*0.45 !important;
            height: 455px*0.45;
        }
        .slick-dots {
            width: 70px;
            list-style: none;
            position: absolute;
            left: 42%;
            bottom: -150px;

            &::before {
                display: none;
            }
        }

        .controls {
            width: 120px;
            margin: 0 auto;
            margin-top: 49px;
        }
    }
}