@import "../../../app/styles/globalStyles";

.form-page {
  width: 100%;
  padding: 70px;
  background-color: #C8FFDF;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &__container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px 27px;

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #2ECC71;
      margin-bottom: 18px;
    }

    &-desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 20px;
    }
    .skip {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-decoration: underline;
      color: #000000;
      margin-top: 20px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-input {
      width: 350px;
      border: 1px solid #000000;
      border-radius: 8px;
      padding: 13px 15px;
      margin-bottom: 20px;
    }
    .formPage__form_password {
      width: 350px;

      input {
        width: 100%;
        height: 46px;
      }
      button {
        position: absolute;
        right: 20px;
        top: 15px;
        background: transparent;
        border: none;
      }
    }

    &-error {
      margin-bottom: 5px;
      color: red;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &-checkbox {
      display: flex;
      max-width: 350px;
      align-items: flex-start;
      margin-bottom: 27px;

      &-star {
        color: red;
        font-weight: 400;
        font-size: 20px;
        line-height: 17px;
        margin-right: 5px;

        &_disabled {
          display: none;
        }
      }

      input {
        border: 1px solid #000000;
        border-radius: 1px;
        margin-right: 11px;
      }

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }

    &-button {
      background: #2ECC71;
      border-radius: 60px;
      min-width: 195px;
      border: none;
      padding: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      transition: all 0.5s;
      &_disabled {
        background: gray;
        border-radius: 60px;
        min-width: 195px;
        border: none;
        padding: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        transition: all 0.5s;
        cursor: not-allowed;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #000000;
      border-radius: 8px;
    }


  }
}

@media screen and (max-width: 500px) {
  .form-page {
    width: 100%;
    padding: 15px;
    background-color: #C8FFDF;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    &__container {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 10px 10px;

      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #2ECC71;
        margin-bottom: 18px;
      }

      &-desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-input {
        width: 350px;
        border: 1px solid #000000;
        border-radius: 8px;
        padding: 13px 15px;
        margin-bottom: 20px;
      }

      &-checkbox {
        display: flex;
        max-width: 350px;
        align-items: flex-start;
        margin-bottom: 27px;

        input {
          border: 1px solid #000000;
          border-radius: 1px;
          margin-right: 11px;
        }

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }
      }

      &-button {
        background: #2ECC71;
        border-radius: 60px;
        min-width: 195px;
        border: none;
        padding: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #000000;
        border-radius: 8px;
      }


    }
  }
}

