@import "../../../app/styles/globalStyles";



.EditOnlineLesson {
    width: 955px;
    margin: 0 auto;
    padding-top: 80px;
    gap: 50px;

    @include flex(flex, column, nowrap, flex-start, flex-start, unset);

    .EditOnlineLesson_header {
        width: 100%;

        @include flex(flex, row, nowrap, space-between, center, unset);

        h1 {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            color: #000000;
        }
        .btns {
            gap: 50px;

            @include flex(flex, row, nowrap, flex-end, center, unset);

            button {
                padding: 10px 33px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                border: none;
                outline: none;
                background-color: transparent;
                border: 2px solid #2ECC71;
                border-radius: 60px;

                &.save {
                    background: #2ECC71;
                    color: #FFFFFF;
                }
            }
        }
    }
    .rowBlock {
        width: 100%;
        gap: 11px;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        input,
        select {
            width: 100%;
            height: 59px;
            border: 1px solid #5A5A5A;
            border-radius: 6px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #5A5A5A;
            padding-left: 20px;

            &:disabled {
                opacity: 0.5;
            }
        }
        select {
            cursor: pointer;
        }
        .rowBlock_header {
            width: 100%;
            margin-bottom: 10px;

            @include flex(flex, row, nowrap, space-between, center, unset);

            .addStudentBtn {
                border: none;
                outline: none;
                background-color: transparent;
                gap: 13px;

                @include flex(flex, row, nowrap, flex-start, flex-end, unset);

                span {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }
                .icon {
                    width: 18px;
                    height: 18px;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .rowBlock_title {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #2ECC71;
        }
        .calendarWrapper {
            .examCalendar {
                width: 100%;
                border: 2px solid #000000;
                border-radius: 20px;

                .react-calendar__navigation {
                    width: 450px;
                    margin: 28px auto;

                    .react-calendar__navigation__label__labelText {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 45px;
                        color: #000000;
                    }
                    .react-calendar__navigation__prev-button,
                    .react-calendar__navigation__next-button,
                    .react-calendar__navigation__prev2-button,
                    .react-calendar__navigation__next2-button {
                        min-width: auto;
                        width: 25px;
                        height: 25px;
                        background: #2ECC71;
                        border-radius: 50%;
                        margin: auto 10px;
                        font-size: 30px;
                        
                        @include flex(flex, row, nowrap, center, center, unset);
                    }
                }
                .react-calendar__month-view__weekdays {
                    border-bottom: 2px solid #000000;
                    padding: 5px 0;

                    .react-calendar__month-view__weekdays__weekday {
                        abbr {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 29px;
                            color: #000000;
                            text-decoration: none;
                        }
                    }
                }
                .react-calendar__month-view__days {
                    padding:  22px 0;
                    justify-content: center;
                    gap: 35px 96px;

                    .react-calendar__tile {
                        flex: none !important;
                        min-width: 0;
                        width: 40px;
                        height: 40px;
                        background: #2ECC71;
                        border-radius: 50%;

                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;

                        &.react-calendar__month-view__days__day--neighboringMonth {
                            background: transparent;
                            color: #949494;
                        }
                        &.react-calendar__tile--active {
                            transform: scale(1.1);
                            border: 2px solid #000;

                            &:disabled {
                                opacity: 0.5;
                                transform: scale(1);
                                border: none;
                            }
                        }
                    }
                    .free {
                        opacity: 1;
                    }
                    &[disabled] {
                        opacity: 0.5;
                    }
                }
            }
        }
        .lessonDateTime {
            p {
                display: inline;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                margin-right: 50px;
            }
            button {
                padding: 10px 33px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                border: none;
                outline: none;
                background-color: transparent;
                border: 2px solid #2ECC71;
                border-radius: 60px;
            }
        }
        .timeInputsWrapper {
            @include flex(flex, row, nowrap, flex-start, center, unset);
            gap: 5px;

            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;

            input {
                width: 50px;
                height: 46px;
                padding: 0;
                border: 2px solid #000000;
                border-radius: 9px;
                text-align: center;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #000000;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }
        }
        .lessonStudentsTable_w {
            width: 100%;

            table {
                width: 100%;
                border-collapse: collapse;

                th {
                    height: 61px;
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #000000;
                    text-align: left;
                    
                    &:first-child {
                        padding-left: 48px;
                    }
                }
                tbody {
                    tr {
                        td {
                            background-color: #0096C1;
                            padding: 34px 0;
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            color: #FFFFFF;

                            &:first-child {
                                padding-left: 48px;
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }
                            &:last-child {
                                padding-right: 10px;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                            button {
                                background-color: transparent;
                                border: none;
                                outline: none;
                            }
                        }
                        &:nth-child(2n + 1) {
                            td {
                                background-color: #006380;
                            }
                        }
                    }
                }
            }

            .noStudents {
                width: fit-content;
                margin: 0 auto;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000;
            }
        }
    }
}






@media screen and (max-width: 500px) {
    .EditOnlineLesson {
        width: 100%;
        padding-top: 0;
        gap: 20px;
    
        .EditOnlineLesson_header {
            width: 100%;
            margin-bottom: 20px;
            flex-wrap: wrap;
            gap: 10px;

            h1 {
                font-size: 36px*0.8;
                line-height: 45px*0.8;
                margin-bottom: 10px;
            }
            .btns {
                gap: 20px;
                flex-wrap: wrap;
    
                button {
                    padding: 10px 33px;
                    font-size: 16px;
                    line-height: 20px;
                    border: 2px solid #2ECC71;
                    border-radius: 60px;
                }
            }
        }
        .rowBlock {
            input,
            select {
                font-size: 18px;
                line-height: 22px;
                padding-left: 20px;
            }
            input {
                padding-right: 20px;
            }
            .rowBlock_header {
                width: 100%;
                margin-bottom: 10px;
                gap: 20px;
    
                @include flex(flex, row, wrap, space-between, center, unset);
    
                .addStudentBtn {
                    gap: 13px;
    
                    @include flex(flex, row, nowrap, flex-start, flex-end, unset);
                }
            }
            .calendarWrapper {
                .examCalendar {
                    width: 100%;
                    border: 2px solid #000000;
                    border-radius: 20px;

                    .react-calendar__navigation {
                        width: 100%;
                        margin: 20px auto;

                        .react-calendar__navigation__label__labelText {
                            font-size: 36px*0.6;
                            line-height: 45px*0.6;
                        }
                        .react-calendar__navigation__prev-button,
                        .react-calendar__navigation__next-button,
                        .react-calendar__navigation__prev2-button,
                        .react-calendar__navigation__next2-button {
                            font-size: 25px;
                            color: #000;
                        }
                    }
                    .react-calendar__month-view__weekdays {
                        padding: 0;

                        .react-calendar__month-view__weekdays__weekday {
                            abbr {
                                font-size: 24px*0.6;
                                line-height: 29px*0.6;
                            }
                        }
                    }
                    .react-calendar__month-view__days {
                        padding: 20px 0;
                        gap: 80px*$TELvw*0.19 96px*$TELvw*0.19;

                        .react-calendar__tile {
                            width: 40px*$TELvw*0.7;
                            height: 40px*$TELvw*0.7;
                            font-size: 18px*$TELvw*0.7;
                            line-height: 22px*$TELvw*0.7;
                        }
                    }
                }
            }
            .lessonDateTime {
                p {
                    display: block;
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
            }
            .lessonStudentsTable_w {
                width: 100%;
    
                table {
                    th {
                        height: 61px*0.6;
                        font-size: 20px*0.6;
                        line-height: 25px*0.6;
                        
                        &:first-child {
                            padding-left: 28px*0.6;
                        }
                    }
                    tbody {
                        tr {
                            td {
                                padding: 34px*0.6 5px;
                                font-size: 18px*0.6;
                                line-height: 22px*0.6;
    
                                &:first-child {
                                    padding-left: 28px*0.6;
                                    border-top-left-radius: 8px*0.6;
                                    border-bottom-left-radius: 8px*0.6;
                                }
                                &:last-child {
                                    padding-right: 28px*0.6;
                                    border-top-right-radius: 8px*0.6;
                                    border-bottom-right-radius: 8px*0.6;
                                }
                            }
                        }
                    }
                }

                .noStudents {
                    font-size: 18px*0.6;
                    line-height: 22px*0.6;
                }
            }
        }
    }
}