@import "../../app/styles/globalStyles";



.AboutPage {
    width: 100%;
    height: 100%;

    .pageContent {
        width: 100%;
        margin-top: 100px;

        .mission {
            width: 100%;
            gap: 68px;
            margin-bottom: 168px;

            @include flex(flex, column, nowrap, flex-start, center, unset);

            .row {
                width: 100%;

                @include flex(flex, row, nowrap, space-between, center, unset);

                &:nth-child(2n) {
                    flex-direction: row-reverse;
                }

                .text {
                    width: 595.93px;

                    span {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 25px;
                        color: #989898;
                    }
                    h3 {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 45px;
                        color: #2ECC71;
                        margin-bottom: 16px;
                    }
                    p {
                        font-family: 'Gilroy';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        color: #5C5C5C;
                    }
                }
                .imgs {
                    width: 741.56px;
                    height: 405.28px;

                    @include flex(flex, row, nowrap, space-between, center, unset);

                    .img {
                        width: 359.49px;
                        height: 405.24px;
                        border-radius: 10px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    video {
                        width: 625px;
                        height: 416.02px;
                        border-radius: 10px;
                    }
                }
            }
        }
        .slider {
            margin-bottom: 100px;
        }
    }
}
.pageWrapper {
    width: 100%;
    padding: 40px 240px;
}









@media screen and (max-width: 500px) {
    .AboutPage {
        width: 100%;
        height: 100%;

        .pageContent {
            width: 100%;
            margin-top: 50px;

            .mission {
                width: 100%;
                gap: 50px;
                margin-bottom: 50px;

                .row {
                    width: 100%;

                    @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                    &:nth-child(2n) {
                        flex-direction: column;
                    }

                    .text {
                        width: 100%;

                        span {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 25px;
                            color: #989898;
                        }
                        h3 {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 45px;
                            color: #2ECC71;
                            margin-bottom: 16px;
                        }
                        p {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 28px;
                            color: #5C5C5C;
                        }
                    }
                    .imgs {
                        width: 100%;
                        height: auto;
                        gap: 20px;
                        margin-top: 50px;

                        @include flex(flex, row, nowrap, space-between, center, unset);

                        .img {
                            width: 50%;
                            height: 50%;
                        }
                        video {
                            width: 100%;
                            height: 310px;
                            border-radius: 10px;
                        }
                    }
                }
            }
            .slider {
                margin-bottom: 50px;
            }
        }
    }
    .pageWrapper {
        width: 100%;
        padding: 20px;
    }
    
}