

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 64px);
  //padding: 100px 0;
  background: #C8FFDF;
  position: relative;
  z-index: 0;

  .loading__container{
    display: flex;
    flex-direction: column;
    align-items: center;
  
    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #2ECC71;
      margin-bottom: 20px;
      text-align: center;
    }
  
    &-desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #4F4F4F;
      margin-bottom: 66px;
      text-align: center;
    }
    .error {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FF2323;
      margin-top: 20px;
    }
  }
  
  .gradient {
    width: 300px;
    height: 300px;
    position: relative;
    border-radius: 50%;
    background: #FFFFFF;
  }
  
  .gradient:before,
  .gradient:after {
    content: "";
    position: absolute;
    top: -16px;
    left: -16px;
    background: linear-gradient(45deg, #2ECC71, #21C5EF);
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    z-index: -1;
    border-radius: 50%;
    background-size: 400%;
    animation: animate 15s linear infinite;
  }
  
  .gradient:after {
    filter: blur(10px);
  }
  
  @keyframes animate {
    0%{
      background-position: 0 0;
    }
    50%{
      background-position: 200% 0;
    }
    100%{
      background-position: 0 0;
    }
  }
}


@media screen and (max-width: 500px) {

  .loading-wrapper  {
    .gradient {
      width: 200px;
      height: 200px;
      position: relative;
      border-radius: 50%;
      background: #FFFFFF;
    }
    .loading__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      
    }
  }
}