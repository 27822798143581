@import "../../../../app/styles/globalStyles";



.ChoozeStudentModal {
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .bg {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;

        .close {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 102;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .header {
        width: 1440px;
        padding: 30px 40px;
        margin: 0 auto;
        margin-bottom: 50px;
        position: relative;
        z-index: 101;
        background: #2ECC71;
        border-radius: 18px;

        @include flex(flex, row, nowrap, space-between, center, unset);

        form {
            @include flex(flex, row, nowrap, flex-start, center, unset);

            gap: 45px;

            .col {
                @include flex(flex, column, nowrap, flex-start, flex-start, unset);

                .label {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    margin-bottom: 10px;
                    padding-left: 10px;
                }
                input {
                    width: 214px;
                    height: 35px;
                    background: #FFFFFF;
                    border-radius: 40px;
                    border: none;
                    outline: none;
                    padding-left: 10px;
                }
            }
        }
        button {
            padding: 10px 42px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            border: none;
            outline: none;
            border: 2px solid #FFFFFF;
            border-radius: 60px;
            background-color: transparent;
        }
    }
    .content {
        width: 1440px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 101;

        ::-webkit-scrollbar {
            display: none;
        }
        .table {
            width: 100%;
            margin: auto;

            .img {
                width: 130px;
            }
            .id {
                width: 150px;
            }
            .fio,
            .email {
                width: 447px;
            }
            .fio {
                width: 600px;
            }
            .status {
                width: 150px;
            }
            .btn {
                width: 42px;
            }
            .rowImg {
                width: 61px;
                height: 61px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .addStudentBtn {
                width: 35px;
                height: 35px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .table-tr {
            display: flex;
            align-items: center;

            &.noRows {
                background-color: transparent !important;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .table-td {
            /* width: 20%; */
        }
        .table-body {
            .table-tr {
                padding: 14px 37px;
                border-radius: 8px;
            }
            .table-td {
                /* width: 20%; */
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
            }
        }
        
        .table-header {

            .table-tr {
                padding: 0 37px;
            }
            .table-td {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #FFFFFF;
                margin-bottom: 29px;
            }
        }
        .table-body {
            overflow-y: scroll;
            max-height: 368px;
        }
        
        .table > .table-body > .table-tr:first-child > .table-td {
            border-top: none;
        }
        .table > .table-body > .table-tr:last-child > .table-td {
            border-bottom: none;
        }
        .table-tr > .table-td {
            border-bottom: none;
        }
        .table-header .table-td {
            
        }
        .table-body .table-tr:nth-child(2n + 1) {
            background-color: #006380;
        }
        .table-body .table-tr:nth-child(2n) {
            background-color: #0096C1;
        }
        
        .pagination {
            width: fit-content;
            margin: 0 auto;
            margin-top: 30px;
        }
    }
}










@media screen and (max-width: 500px) {
    .ChoozeStudentModal {
        padding-top: 80px;
        overflow-y: auto;

        .bg {
            position: fixed;

            .close {
                width: 21px;
                height: 21px;
                top: 20px;
                right: 20px;
            }
        }

        .header {
            width: 90%;
            padding: 20px;
            margin-bottom: 50px;
            border-radius: 10px;
    
            @include flex(flex, row, wrap, center, center, unset);
    
            form {
                width: 100%;
                margin-bottom: 20px;

                @include flex(flex, row, wrap, flex-start, flex-start, unset);
    
                gap: 20px;
    
                .col {
                    width: 100%;

                    input {
                        width: 100%;
                        height: 50px;
                    }
                }
            }
            button {
                padding: 10px 42px;
                font-size: 16px;
                line-height: 20px;
                border: 2px solid #FFFFFF;
                border-radius: 60px;
            }
        }
        .content {
            width: 90%;

            .table {
                overflow-x: auto;

                .img {
                    width: 130px*0.4;
                }
                .id {
                    width: 150px*0.4;
                }
                .fio,
                .email {
                    width: 447px*0.4;
                }
                .status {
                    width: 150px*0.4;
                }
                .btn {
                    width: 42px;
                }
                .rowImg {
                    width: 61px*0.7;
                    height: 61px*0.7;
                    border-radius: 50%;
                    overflow: hidden;
    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .addStudentBtn {
                    width: 35px*0.6;
                    height: 35px*0.6;
                    margin-left: 10px;
                }
            }
            .table-tr {
                display: flex;
                align-items: center;
            }
            .table-td {
                padding: 5px;
            }
            .table-body {
                width: fit-content;

                .table-tr {
                    width: inherit;
                    padding: 14px*0.7 10px;
                    border-radius: 8px*0.7;
                }
                .table-td {
                    font-size: 18px*0.7;
                    line-height: 22px*0.7;
                }
            }
            
            .table-header {
                width: fit-content;

                .table-tr {
                    padding: 0 7px;
                }
                .table-td {
                    font-size: 20px*0.7;
                    line-height: 25px*0.7;
                    margin-bottom: 29px*0.7;
                }
            }
            .table-body {
                overflow-y: scroll;
                max-height: 368px*0.7;
            }
            
            .table > .table-body > .table-tr:first-child > .table-td {
                border-top: none;
            }
            .table > .table-body > .table-tr:last-child > .table-td {
                border-bottom: none;
            }
            .table-tr > .table-td {
                border-bottom: none;
            }
            .table-body .table-tr:nth-child(2n + 1) {
                background-color: #006380;
            }
            .table-body .table-tr:nth-child(2n) {
                background-color: #0096C1;
            }
            
            .pagination {
                width: fit-content;
                margin: 0 auto;
                margin-top: 30px;
            }
        }
    }
}